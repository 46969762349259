<script setup>
// Imports...
import GroupsTable from '@/components/groups/TableLayout.vue';
import AppSpinner from '@/components/ui/AppSpinner.vue';
import ManageGroupsModal from '@/components/ui/modals/ManageGroupsModal.vue';
import NewGroupsModal from '@/components/ui/modals/PropertiesNewGroupsModal.vue';
import { onMounted } from 'vue';
import { useGroupsStore } from '@/stores/GroupsStore';
import { useModalStore } from '@/stores/ModalStore';

const groupsStore = useGroupsStore();
const modalStore = useModalStore();

onMounted(async () => {
  await groupsStore.fetchGroups();
});
</script>

<template>
  <!-- Section Body -->
  <div v-if="!groupsStore.initialLoad" class="styles_view__body">
    <!-- Groups Table -->
    <GroupsTable />
  </div>
  <!-- Show loading spinner while data is loading -->
  <div v-else class="center-spinner">
    <AppSpinner />
  </div>
  <!-- Modals -->
  <ManageGroupsModal v-if="modalStore.isModalOpen('manageGroupsModal')" />
  <NewGroupsModal v-if="modalStore.isModalOpen('newGroupsModal')" />
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
.center-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.no-records {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
  font-size: 2em;
  font-weight: bold;
}
</style>
