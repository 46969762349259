<!-- NewGroupsModal.vue -->
<script setup>
import { ref, computed, watch } from 'vue';
import { newTypeOptions, staticGroupData } from '@/data/groupsData';
import { useModalStore } from '@/stores/ModalStore';
import AppModal from '@/components/ui/AppModal.vue';
import AppTextInput from '@/components/ui/AppTextInput.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue';

const modalStore = useModalStore();
const modalData = computed(() => modalStore.modalData);
const selectedProperties = ref([...(modalData.value.properties || [])]);
const isLoading = computed(() => modalStore.modalLoading);
const saveLoading = ref(false);
const selectedType = ref('');

// Portfolio-specific fields
const portfolioDetail1 = ref('');
const portfolioDetail2 = ref('');

// Prepare the list of all properties to display in the pills component
const allProperties = computed(() => {
  // Use Set to ensure only unique property names
  const uniqueProperties = new Set(staticGroupData.flatMap((group) => group.property_names));
  return Array.from(uniqueProperties);
});

// Watch modalData to initialize selectedProperties when modal opens
watch(
  () => modalData.value.properties,
  (newProperties) => {
    selectedProperties.value = [...(newProperties || [])];
  },
  { immediate: true }
);

// Watch for changes in selectedType to clear portfolio fields when type is not Portfolio
watch(selectedType, (newType) => {
  if (newType !== 'Portfolio') {
    portfolioDetail1.value = '';
    portfolioDetail2.value = '';
  }
});

function handleSave() {
  saveLoading.value = true; // start loading
  modalStore.simulateSave('newGroupsModal');
  // Since simulateSave closes the modal after a delay, we stop loading once done
  setTimeout(() => {
    saveLoading.value = false; // stop loading after action completes
  }, 1000);
}
</script>

<template>
  <teleport to="body">
    <AppModal title="New Group" modalId="newGroupsModal">
      <template #modalBody>
        <div v-if="isLoading" class="loading-state">
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="50px" />
          </div>
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="50px" />
          </div>
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="50px" />
          </div>
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="50px" />
          </div>
          <div class="placeholder-block" style="margin-top: 20px">
            <AppSkeletonPlaceholder width="100%" height="250px" />
          </div>
        </div>

        <div v-else>
          <!-- Name input -->
          <AppTextInput title="Name" placeholder="Enter a name for the new group" class="modal-input" />

          <!-- Type input -->
          <AppSingleSelector title="Type" placeholder="Select a group type" :options="newTypeOptions" v-model="selectedType" class="modal-input" />

          <!-- Properties pills selector -->
          <AppListPills
            title="Properties"
            placeholder="Click to add or remove properties"
            :listItems="allProperties"
            v-model="selectedProperties"
            :useRawItem="true"
          />
        </div>

        <!-- Portfolio specific fields -->
        <div v-if="selectedType === 'Portfolio'">
          <AppListPills
            title="Users"
            placeholder="Manage user access to this group"
            :listItems="allProperties"
            v-model="portfolioDetail1"
            :useRawItem="true"
          />
          <AppListPills
            title="Source Channels"
            placeholder="Select or add source channels"
            :listItems="allProperties"
            v-model="portfolioDetail2"
            :useRawItem="true"
          />
          <AppListPills
            title="Categories"
            placeholder="Select or add categories"
            :listItems="allProperties"
            v-model="portfolioDetail3"
            :useRawItem="true"
          />
        </div>
      </template>

      <template #modalFooter>
        <AppButton label="Save" :isLoading="saveLoading" @clicked="handleSave" />
      </template>
    </AppModal>
  </teleport>
</template>
