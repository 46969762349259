// budgets.js
export const budgetOptionsChartData = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Line Chart Example',
  },
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    y: {
      min: 0,
      max: 3000,
      ticks: {
        font: {
          size: 14,
        },
        callback: function (value) {
          return '$' + value;
        },
      },
      labels: {
        callback: function (value) {
          return '$' + value;
        },
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        font: {
          size: 14,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const budgetOptionsData = [
  { label: 'Current', value: 'Current' },
  { label: 'Working', value: 'Working' },
  { label: 'Reduced', value: 'Reduced' },
];

export const budgetYearOptionsData = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
];

export const budgetCardData = [
  {
    id: 1,
    name: 'Budget A',
    creator: 'John Todero',
    lastEdited: 'Jan 14 2025',
    total: 3496.67,
    year: '2025',
    type: 'Current',
  },
  {
    id: 2,
    name: 'Budget B',
    creator: 'Sophia Napoles',
    lastEdited: 'Jan 15 2025',
    total: '60% Budget Remaining',
    year: '2025',
    type: 'Working',
  },
  {
    id: 3,
    name: 'Budget C',
    creator: 'John Todero',
    lastEdited: 'Jan 16 2025',
    total: 2000.5,
    year: '2025',
    type: 'Reduced',
  },
  {
    id: 4,
    name: 'Budget D',
    creator: 'Sophia Napoles',
    lastEdited: 'Jan 17 2025',
    total: '60% Budget Remaining',
    year: '2025',
    type: 'Current',
  },
  {
    id: 5,
    name: 'Budget E',
    creator: 'John Todero',
    lastEdited: 'Jan 18 2025',
    total: 1550,
    year: '2025',
    type: 'Working',
  },
  {
    id: 6,
    name: 'Budget F',
    creator: 'John Todero',
    lastEdited: 'Jan 19 2025',
    total: '30% of Budget Utilized',
    year: '2025',
    type: 'Reduced',
  },
];
