<!-- AppHeaderSearch.vue -->
<script setup>
import AppIconSearch from '@/components/svg/AppIconSearch.vue';
import { ref } from 'vue';
import KaiChat from '@/components/ui/modals/KaiModal.vue';
import { v4 as uuidv4 } from 'uuid';
import { useModalStore } from '@/stores/ModalStore';
import { useKaiStore } from '@/stores/KaiStore';
import KaiSearchBar from '@/components/ui/search/KaiSearchBar.vue';

const modalStore = useModalStore();
const kaiStore = useKaiStore();
const dataVisible = ref(false);
const isHovered = ref(false);
const userInput = ref('');
const initialMessage = ref(null);

const kaiWords = [
  'how',
  'can',
  'what',
  'when',
  'where',
  'why',
  'generate',
  'who',
  'will',
  'please',
  'assist',
  'tell',
  'help',
  'explain',
  'create',
  'describe',
  'summarize',
  'define',
  'interpret',
  'show',
  'find',
  'give',
  'provide',
  'suggest',
  'recommend',
  'consider',
  'imagine',
  'suppose',
  'think',
  'believe',
  'understand',
  'know',
  'learn',
  'discover',
  'explore',
  '?',
];

const detectKaiWords = (text) => {
  if (!text) return 'unknown';

  const words = text.toLowerCase().split(/\s+/);
  const containsKaiWord = words.some((word) => kaiWords.includes(word));

  const inputType = containsKaiWord ? 'natural language' : words.length > 1 ? 'search' : 'unknown';
  kaiStore.setInputType(inputType);

  return inputType;
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    handleSubmission();
  }
};

const handleSubmission = () => {
  const inputText = userInput.value.trim();
  if (!inputText) return;

  const inputType = detectKaiWords(inputText);

  if (inputType === 'natural language') {
    const message = {
      id: uuidv4(),
      text: inputText,
      sender: 'user',
    };
    initialMessage.value = message;
    modalStore.openModal('chat-modal', { title: 'Chat with Kai' });
  } else if (inputType === 'search') {
    console.log('Performing search:', inputText);
  }

  userInput.value = '';
};

const handleFormSubmit = (event) => {
  event.preventDefault();
  handleSubmission();
};

const handleBlur = () => {
  window.setTimeout(() => {
    dataVisible.value = false;
  }, 200);
};
</script>

<template>
  <div class="search" :class="{ 'is-active': dataVisible }" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="search__body">
      <KaiSearchBar />
    </div>
  </div>

  <KaiChat :initial-message="initialMessage" />
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.ico-search {
  position: absolute;
  top: 52%;
  left: 20px;
  transform: translateY(-50%);
}

/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 496px;
  z-index: 1;

  /*  Large Desktop  */

  @include large-desktop {
    width: 496px;
  }

  /*  Mobile  */

  @include mobile {
    width: 300px;
  }

  &:hover {
    form {
      opacity: 1;
      visibility: visible;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 44px;
    transform: translateY(-50%);
    font-size: 21px;
    font-weight: 500;
    border-right: var(--border-size) solid var(--primary-color);
    padding-right: 12px;

    /*  Mobile  */

    @include mobile {
      font-size: 9px;
      left: 25px;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  form {
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.4s,
      visibility 0.4s;
  }

  .search__field {
    width: 100%;
    border: var(--border-size) solid var(--primary-color);
    border-radius: 12px;
    height: 50px;
    background: none;
    padding-left: 50px;
    padding-right: 20px;

    /*  Mobile  */

    @include mobile {
      height: 35px;
      border-radius: 6px;
      padding: 0 10px 0 37px;
    }
  }

  .search__body {
    position: relative;

    .ico-search {
      position: absolute;
      top: 52%;
      left: 12px;
      transform: translateY(-50%);
      transition: all 0.3s ease;
    }

    .search__btn {
      position: absolute;
      top: 55%;
      left: 12px;
      transform: translateY(-50%);
      opacity: 0;

      /*  Mobile  */

      @include mobile {
        left: 5px;
      }
    }
  }

  .search__foot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--dropdown-bg);
    border: var(--border-size) solid var(--secondary-color);
    box-shadow: 0 33px 22px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    margin-top: 2px;
    display: none;

    /*  Mobile  */

    @include mobile {
      border-radius: 7px;
      margin-top: 5px;
    }

    h6 {
      border-bottom: var(--border-size) solid var(--primary-text-color);
      padding: 19px 27px;
      font-size: 21px;

      /*  Mobile  */

      @include mobile {
        //font-size: 10px;
        //padding: 10px;
      }
    }

    ul {
      list-style: none;
      font-size: 16px;
      padding: 6px 14px 16px;

      /*  Mobile  */

      @include mobile {
        //font-size: 10px;
        //padding: 3px 7px 8px;
      }

      a {
        text-decoration: none;
        position: relative;
        padding: 10px 42px;
        border-radius: 7px;
        display: block;
        transition:
          background 0.4s,
          color 0.4s;

        /*  Mobile  */

        @include mobile {
          //padding: 6px 25px;
          //border-radius: 4px;
        }

        &:hover {
          background: var(--primary-color);
          // color: var(--page-bg);

          &:after {
            opacity: 0.3;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          background: url(@/assets/svg/ico-angle-down.svg) no-repeat 0 0;
          width: 14px;
          height: 14px;
          transform: translateY(-50%) rotate(45deg);
          background-size: 100% 100%;
          transition: opacity 0.4s;

          /*  Mobile  */

          @include mobile {
            //width: 12px;
            //height: 7px;
            //left: 5px;
          }
        }
      }

      li {
        + li {
          margin-top: 5px;
        }
      }
    }
  }

  &.is-active {
    .search__foot {
      display: block;
    }
  }
}

/* ------------------------------------------------------------ *\
	Search Alt
\* ------------------------------------------------------------ */

.search-alt {
  position: relative;

  form {
    position: relative;
  }

  .hidden {
    display: none;
  }

  .search__btn {
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(-50%);
    background: none;
    border: none;

    /*  Mobile  */

    // @include mobile {
    // 	line-height: 0;
    // 	left: 10px;
    // }
  }

  .search__field {
    width: 100%;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    height: 44px;
    background: none;
    padding: 0 0 0 45px;
    color: var(--primary-text-color);
    transition: 0.4s;

    /*  Mobile  */

    // @include mobile {
    // 	height: 35px;
    // 	font-size: 12px;
    // 	padding-left: 30px;
    // }

    &:focus,
    &:hover {
      border-color: var(--primary-color);
    }
  }
}

#properties,
#users,
#alerts {
  .search__field.keyword__field {
    padding: 0 10px 0 15px;
  }
}

.input-label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: var(--secondary-color);
  margin-bottom: 5px;
  padding-left: 16px;
}

::placeholder {
  color: var(--secondary-color);
}
</style>
