<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { usePropertiesStore } from '@/stores/PropertiesStore';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppIconDetailView from '@/components/svg/AppIconDetailView.vue';
import AppIconListView from '@/components/svg/AppIconListView.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';

// 1. Access the PropertiesStore
const propertiesStore = usePropertiesStore();

// 2. Overlay logic
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  if (option === 'export-to-csv') {
    propertiesStore.exportPropertiesAsCsv();
  }
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <!-- The page title always remains -->
        <h3 class="page-title">Properties</h3>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <AppActionButton @click="handleOptionClicked" label="New Property">
          <AppIconAddAlt />
        </AppActionButton>

        <!-- View style toggles for grid, detail, list, etc. -->
        <div class="properties-view-options-container">
          <AppIconDetailView @click="propertiesStore.toggleView('detail-grid')" :isActive="propertiesStore.currentPropertyView === 'detail-grid'" />
          <AppIconListView @click="propertiesStore.toggleView('list')" :isActive="propertiesStore.currentPropertyView === 'list'" />
        </div>

        <!-- Dots menu overlay -->
        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
            <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="['export-to-csv', 'settings']" bodyWidth="160px">
              <template #export-to-csv>
                <div>Export list to CSV</div>
              </template>
              <template #settings>
                <div>View preferences</div>
              </template>
            </AppOverlayMenu>
          </AppIconBoxWrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

// Container styling for the grid/detail/list view icons
.properties-view-options-container {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  gap: 15px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}
.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
