// stores/GroupsStore.js
import { defineStore } from 'pinia';
import { staticGroupData } from '@/data/groupsData.js';

export const useGroupsStore = defineStore({
  id: 'groupsStore',
  state: () => ({
    groups: [],
    filteredGroups: [],
    searchQuery: '',
    selectedGroupType: 'All',
    isLoading: false,
    initialLoad: false,
  }),
  getters: {
    finalGroups: (state) => {
      return state.filteredGroups.length ? state.filteredGroups : state.groups;
    },
    isFilterApplied: (state) => {
      return state.searchQuery !== '' || state.selectedGroupType !== '';
    },
  },
  actions: {
    fetchGroups() {
      this.initialLoad = true;
      // Simulate an API call
      setTimeout(() => {
        // store the raw data from groupsData
        this.groups = staticGroupData;
        // initially display them unfiltered
        this.filteredGroups = [...staticGroupData];
        this.initialLoad = false;
      }, 1000);
    },

    applyFilters() {
      this.filterLoading = true;
      let tempArray = [...this.groups];

      if (this.searchQuery) {
        const lowerQ = this.searchQuery.toLowerCase();
        tempArray = tempArray.filter((g) => g.name?.toLowerCase().includes(lowerQ));
      }

      switch (this.selectedGroupType) {
        case 'Default':
          tempArray = tempArray.filter((g) => g.tags === 'DEFAULT');
          break;

        case 'Portfolio':
          tempArray = tempArray.filter((g) => g.tags === 'PORTFOLIO');
          break;

        case 'Smart':
          tempArray = tempArray.filter((g) => g.tags === 'SMART');
          break;

        case '': // "All Types"
        default:
          break;
      }

      this.filteredGroups = tempArray;
      this.filterLoading = false;
    },

    resetFilters() {
      this.searchQuery = '';
      this.selectedGroupType = '';
      this.filteredGroups = [...this.groups];
    },
  },
});
