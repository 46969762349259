// composables/useCursorDropdown.js
import { ref, onMounted, onUnmounted } from 'vue';
import { onClickOutside } from '@vueuse/core';

export function useCursorDropdown() {
  // Single dropdown => use a boolean
  const showOverlay = ref(false);
  const userPosition = ref({ x: 0, y: 0, z: 1000 });
  const dropdownTarget = ref(null);

  function toggleOverlay(event) {
    // Make sure we received an event
    if (!event) return;
    event.stopPropagation();

    // Update cursor position
    userPosition.value = {
      x: event.clientX,
      y: event.clientY,
      z: userPosition.value.z + 1, // If multiple overlays, increment the z-index
    };

    // Toggle showing/hiding the overlay
    showOverlay.value = !showOverlay.value;
  }

  function handleScroll() {
    // Close the overlay on scroll
    if (showOverlay.value) {
      showOverlay.value = false;
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  // Close the dropdown if clicking outside its container
  onClickOutside(dropdownTarget, () => {
    showOverlay.value = false;
  });

  return {
    showOverlay,
    userPosition,
    dropdownTarget,
    toggleOverlay,
  };
}
