<!-- DashboardLinks.vue -->
<script setup>
import { useDashboardsStore } from '@/stores/DashboardsStore';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
const dashboardStore = useDashboardsStore();

const props = defineProps({
  dashboardId: {
    type: Number,
    required: true,
  },
});

function handleOptionClicked(option) {
  if (option === 'viewDashboardOption') {
    if (props.dashboardId) {
      dashboardStore.loadLayout(props.dashboardId);
    } else {
      console.error('Dashboard ID is invalid:', props.dashboardId);
    }
  } else if (option === 'customizeOption') {
    console.log('Customize clicked. Dashboard ID:', props.dashboardId);
  }
}
</script>

<template>
  <div class="property-links">
    <AppOverlayMenu @option-clicked="handleOptionClicked" :options="['viewDashboardOption', 'customizeOption']">
      <template #viewDashboardOption>
        <a class="overlay-menu-link">
          <p class="link-text">View</p>
        </a>
      </template>

      <template #customizeOption>
        <a class="overlay-menu-link">
          <p class="link-text">Customize</p>
        </a>
      </template>
    </AppOverlayMenu>
  </div>
</template>

<style scoped lang="scss">
.property-links {
  position: relative;

  .overlay-menu-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--primary-text-color);
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    opacity: 0.75;
    border-radius: 7px;

    .link-text {
      padding: 5px 30px 5px 5px;
    }

    &:hover {
      opacity: 100;
      transition: all 0.3s ease;
      background: var(--property-metric-bg);
      background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);

      .nav-icon {
        color: var(--primary-color);
      }
    }
  }
}

:deep(.overlay-menu ul) {
  padding: 7px;
}

:deep(.overlay-menu li) {
  opacity: 100;
  padding: 5px 10px;

  &:hover {
    opacity: 1;
    background: transparent;
    cursor: default;
    transition: none;
  }
}
</style>
