// TrendingReportStore.js
import { defineStore } from 'pinia';
import { useCsv } from '@/composables/useCsv';

// functions for date parsing + formatting - we could also make a helper util and move it there
function parseDateString(dateStr) {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
}

function formatDate(date) {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// generateDateRanges: Based on "startDay", "endDay", "numPeriods", "periodType".
// this function automatically builds an array of date ranges so the UI can display columns or aggregates per period.

function generateDateRanges(startDateStr, endDateStr, numPeriods, periodType) {
  const dateRanges = [];
  let currentEndDate = parseDateString(endDateStr);
  const startDate = parseDateString(startDateStr);

  for (let i = 0; i < numPeriods; i++) {
    let periodEnd = new Date(currentEndDate);
    let periodStart = new Date(periodEnd);

    switch (periodType) {
      case 1: // Daily
        periodStart = new Date(periodEnd);
        break;
      case 2: // Weekly
        periodStart.setUTCDate(periodStart.getUTCDate() - 6);
        break;
      case 3: // 30 day
        periodStart.setUTCDate(periodStart.getUTCDate() - 29);
        break;
      case 4: // Monthly
        periodStart.setUTCDate(1);
        break;
      case 5: // Yearly
        periodStart.setUTCMonth(0);
        periodStart.setUTCDate(1);
        break;
      default:
        break;
    }

    // Ensure periodStart doesn’t go before the initial startDate
    if (periodStart < startDate) {
      periodStart = new Date(startDate);
    }

    dateRanges.unshift({
      start: formatDate(periodStart),
      end: formatDate(periodEnd),
    });

    // Move currentEndDate to the end of the previous period
    switch (periodType) {
      case 1: // Daily
        currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 1);
        break;
      case 2: // Weekly
        currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 7);
        break;
      case 3: // 30 day
        currentEndDate.setUTCDate(currentEndDate.getUTCDate() - 30);
        break;
      case 4: // Monthly
        currentEndDate.setUTCDate(1);
        currentEndDate.setUTCDate(0); // last day of previous month
        break;
      case 5: // Yearly
        currentEndDate.setUTCMonth(0);
        currentEndDate.setUTCDate(1);
        currentEndDate.setUTCDate(0); // last day of previous year
        break;
      default:
        break;
    }

    if (currentEndDate < startDate) {
      break;
    }
  }

  return dateRanges;
}

// Compute default start/end days for the initial state
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const currentDay = currentDate.getDate();
const currentDateString = `${currentYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}-${currentDay < 10 ? `0${currentDay}` : currentDay}`;

const startDay = new Date(currentYear, currentMonth - 6, 1);
const startYear = startDay.getFullYear();
const startMonth = startDay.getMonth() + 1;
const startDayOfMonth = startDay.getDate();
const startDayString = `${startYear}-${startMonth < 10 ? `0${startMonth}` : startMonth}-${startDayOfMonth < 10 ? `0${startDayOfMonth}` : startDayOfMonth}`;

export const useTrendingReportStore = defineStore('TrendingReportStore', {
  state: () => ({
    trendingReport: {
      report_type: 'trending_report',
      period_type: 3, // 1: daily, 2: weekly, 3: monthly
      num_of_periods: 6,
      startDay: startDayString,
      endDay: currentDateString,
    },
    reportName: 'Trending Report',
    selectedRecurring: 'no',
    // The user’s chosen period type, # of periods, etc.
    periodOptions: [
      { label: 'Daily', value: 1 },
      { label: 'Weekly', value: 2 },
      { label: '30 day', value: 3 },
      { label: 'Monthly', value: 4 },
      { label: 'Yearly', value: 5 },
    ],
    selectedPeriod: 3,
    numPeriodOptions: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
    ],
    numSelectedPeriod: 6,
    // The different “report sections” (like GA4, Ads, etc.)
    reportOptions: [
      { label: 'Website Performance', value: 'website_performance' },
      { label: 'Ads Search', value: 'ads_search' },
      { label: 'Ads Site Remarketing', value: 'ads_site_remarketing' },
      { label: 'Ads In-Market Audiences', value: 'ads_in_market_audiences' },
      { label: 'Ads Custom Intent Audiences', value: 'ads_custom_intent_audiences' },
      { label: 'Ads Demand Gen', value: 'ads_demand_gen' },
      { label: 'Ads P-Max', value: 'ads_p_max' },
      { label: 'Ads Video', value: 'ads_video' },
      { label: 'Google Business Profile', value: 'google_business_profile' },
      { label: 'Meta Ads Performance', value: 'meta_ads_performance' },
      { label: 'Leasing Performance', value: 'leasing_performance' },
      { label: 'LP Conversion Rate', value: 'lp_conversion_rate' },
      { label: 'LP Contact Type', value: 'lp_contact_type' },
      { label: 'Availability', value: 'availability' },
      { label: 'Reputation Overall', value: 'reputation_overall' },
      { label: 'Reputation Google', value: 'reputation_google' },
    ],
    selectedReportOptions: ['website_performance', 'ads_search'],
  }),

  getters: {
    // Return an object with boolean flags for each section that is currently selected.
    report(state) {
      const report = {};
      state.reportOptions.forEach((option) => {
        report[option.value] = state.selectedReportOptions.includes(option.value);
      });
      return report;
    },

    // Build date ranges based on the store’s start/end day, # of periods, etc.
    dateRanges(state) {
      return generateDateRanges(state.trendingReport.startDay, state.trendingReport.endDay, state.numSelectedPeriod, state.selectedPeriod);
    },
  },

  actions: {
    // Toggle a given report section (like “ads_search”) on/off
    toggleOption(option) {
      const index = this.selectedReportOptions.indexOf(option.value);
      if (index > -1) {
        this.selectedReportOptions.splice(index, 1);
      } else {
        this.selectedReportOptions.push(option.value);
      }
    },

    // Update the entire trending report settings in one go

    updateReportSettings(newSettings) {
      this.reportName = newSettings.reportName;
      this.selectedRecurring = newSettings.selectedRecurring;
      this.selectedPeriod = newSettings.selectedPeriod;
      this.numSelectedPeriod = newSettings.numSelectedPeriod;
      this.selectedReportOptions = [...newSettings.selectedReportOptions];

      // Update the store’s "trendingReport" structure
      this.trendingReport.period_type = parseInt(newSettings.selectedPeriod);
      this.trendingReport.num_of_periods = parseInt(newSettings.numSelectedPeriod);
    },

    // Actually export the trending data to CSV.
    // “trendingData” is the combined object from the parent,
    // “dateRanges” is typically this.dateRanges, but can be passed in explicitly.
    exportTrendingReportAsCsv(trendingData, dateRanges = []) {
      const { convertTrendingReportToCsv, downloadCsv } = useCsv();

      // If no dateRanges provided, fallback to store's dateRanges getter
      const finalRanges = dateRanges.length > 0 ? dateRanges : this.dateRanges;

      // Convert to CSV using the specialized function
      const csvString = convertTrendingReportToCsv(trendingData, this.report, finalRanges);

      // Trigger the file download
      const today = new Date().toISOString().slice(0, 10);
      const filename = `trending_report_${today}.csv`;
      downloadCsv(csvString, filename);
    },
  },
});
