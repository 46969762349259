<!-- AppActionButton.vue -->
<script setup>
const props = defineProps({
  label: String,
  isCheck: Boolean,
  isChecked: Boolean,
  isLoading: Boolean,
  clicked: Function,
});

const emit = defineEmits(['clicked']);

function handleClick() {
  emit('clicked', props.label);
}
</script>

<template>
  <button class="btn" @click="handleClick">
    <span v-if="isLoading" class="spinner"></span>
    <template v-else>
      <slot />
      <div class="label-style">{{ label }}</div>
    </template>
  </button>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: var(--border-size) solid var(--secondary-color);
  background-color: var(--primary-color);
  background: none;
  border-radius: 7px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.019em;
  padding: 0px 10px;
  transition: all 0.4s ease;
  min-height: 33px;

  .label-style {
    font-size: 13px;
    font-weight: 400;
    color: var(--secondary-color);
    transition: all 0.4s ease;
  }

  &:hover,
  &.is-active {
    border-color: var(--primary-color);
    color: var(--primary-color);

    :deep(.icon-color) {
      fill: var(--primary-color);
    }

    .label-style {
      color: var(--primary-color);
    }
  }

  &:not(:only-child) {
    margin-right: 15px;
  }

  :deep(.icon-color) {
    width: 9px;
  }
}

/* ------------------------------------------------------------ *\
	Spinner
\* ------------------------------------------------------------ */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  transition:
    all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1),
    opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.spinner {
  &:before {
    content: '';
    box-sizing: border-box;
    position: relative;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 18px;
    border-radius: 50%;
    border-top: var(--border-size) solid #fff;
    border-right: var(--border-size) solid transparent;
    animation: spinner 0.6s linear infinite;
    transition:
      all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1),
      opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  span {
    transition: opacity 0.3s;
  }
}
</style>
