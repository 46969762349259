<!-- ReportsPageHeader.vue -->
<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconInfo from '@/components/svg/AppIconInfo.vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';

import { useReportsStore } from '@/stores/ReportsStore';
import { useModalStore } from '@/stores/ModalStore';
import { useBenchmarkReport } from '@/stores/BenchmarkReportStore';
// data for Benchmark
import combinedData from '@/data/benchmark-report/benchmarkData.json';

const reportsStore = useReportsStore();
const modalStore = useModalStore();

// Overlay logic

const isOverlayVisible = ref(false);
const target = ref(null);
const benchmarkReportStore = useBenchmarkReport();

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  console.log(`Option ${option.text} clicked`);
}

function handleExportCsv() {
  const reportSelected = reportsStore.selectedReport;

  if (reportSelected === 1) {
    benchmarkReportStore.exportBenchmarkReportAsCsv(combinedData);
  } else {
    console.error('CSV export is not supported for this report type:', reportSelected);
  }
}

const handleOpenEditReportModal = (report) => {
  if (report === 1) {
    modalStore.openModal('EditBenchmarkReportModal');
  } else {
    console.error('Invalid report type');
  }
};
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div class="left-side-container">
        <h3>
          {{ reportsStore.reportName }}
          <span class="pill">Property and/or group names</span>
        </h3>
        <AppTooltip>
          <AppIconInfo style="width: 17px; height: 17px" />
          <template #text>
            <span class="description"> Default report settings: 30 day, GA4 & Google Ads Search selected if applicable. </span>
          </template>
        </AppTooltip>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <AppActionButton @click="handleOptionClicked" label="New Report">
          <AppIconAddAlt />
        </AppActionButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

/* Transition classes for fade */
.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}

.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}

.question-icon {
  height: 14px;
  width: 14px;
  position: relative;
  top: 1px;
}

.pill {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  margin: 0 10px;
  padding: 5px 13px;
  font-size: 0.65em;
  color: var(--primary-text-color);
}

.left-side-container {
  display: flex;
  align-items: center;
  height: 34px;
}

.styles_pageHeader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_pageHeader__icon-container {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}
</style>
