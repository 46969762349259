<!-- AppIconAdd.vue -->
<script setup></script>

<template>
  <svg class="icon-color" width="14" height="14" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="colorize"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3058 1.81375C12.3058 0.81204 11.4971 0 10.4997 0C9.50236 0 8.69365 0.81204 8.69365 1.81375V8.68602H1.80608C0.80861 8.68602 0 9.49816 0 10.4998C0 11.5014 0.80861 12.3135 1.80608 12.3135H8.69365V19.1863C8.69365 20.1879 9.50236 21 10.4997 21C11.4971 21 12.3058 20.1879 12.3058 19.1863V12.3135H19.1939C20.1915 12.3135 21 11.5014 21 10.4998C21 9.49816 20.1915 8.68602 19.1939 8.68602H12.3058V1.81375Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.icon-color {
  transition: all 0.4s ease;
  fill: var(--secondary-color);

  &:hover {
    fill: var(--primary-color);
  }
}
</style>
