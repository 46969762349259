// groupsData.js
export const groupTypeOptions = [
  { label: 'Show All', value: 'All' },
  { label: 'Default', value: 'Default' },
  { label: 'Portfolio', value: 'Portfolio' },
  { label: 'Smart', value: 'Smart' },
];

export const newTypeOptions = [
  { label: 'Default', value: 'Default' },
  { label: 'Portfolio', value: 'Portfolio' },
  { label: 'Smart', value: 'Smart' },
];

export const staticGroupData = [
  {
    id: 1,
    name: 'Northeast',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/avatar-main.png' },
      { id: 2, name: 'Max', avatar: '/assets/images/user-4.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-1.jpg' },
    ],
    tags: 'Portfolio',
    property_names: ['1000 South Capitol', '55 Fifty at Northwest Crossing'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 2,
    name: 'Dweck',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-3.jpg' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-1.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-4.jpg' },
    ],
    tags: 'Portfolio',
    property_names: ['12 Central Square'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 3,
    name: 'Avenue5',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-1.jpg' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-8.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-11.jpg' },
    ],
    tags: 'Portfolio',
    property_names: ['401 Teravista', '4730 California'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 10,
    name: 'Low occupancy',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-11.jpg' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-7.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-10.jpg' },
    ],
    tags: 'Smart',
    property_names: ['7th Flats', '100 Park', '110 Roy'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 7,
    name: 'Disabled',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-9.jpg' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-2.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-10.jpg' },
    ],
    tags: 'Default',
    property_names: ['30 Lancaster'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 4,
    name: 'Southeast',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-8.jpg' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-3.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/user-4.jpg' },
    ],
    tags: 'Default',
    property_names: ['7th Flats', '401 Teravista', '4730 California'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 6,
    name: 'Arizona',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/user-9.jpg' },
      { id: 1, name: 'Liam', avatar: '/assets/images/avatar-main.png' },
    ],
    tags: 'Portfolio',
    property_names: ['2828 Zuni'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 8,
    name: 'Demo',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/avatar-main.png' },
      { id: 1, name: 'Liam', avatar: '/assets/images/avatar-main.png' },
    ],
    tags: 'Default',
    property_names: ['50N5th', '401 Teravista'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 9,
    name: 'Lease up',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/avatar-main.png' },
      { id: 1, name: 'Liam', avatar: '/assets/images/avatar-main.png' },
    ],
    tags: 'Smart',
    property_names: ['1000 South Capitol', '12 Central Square', '4730 California'],
    last_updated: 'February 5, 2025',
  },
  {
    id: 5,
    name: 'High exposure',
    users: [
      { id: 1, name: 'Hannah', avatar: '/assets/images/avatar-main.png' },
      { id: 2, name: 'Bob', avatar: '/assets/images/user-1.jpg' },
      { id: 3, name: 'Jamie', avatar: '/assets/images/avatar-main.png' },
    ],
    tags: 'Smart',
    property_names: ['1000 South Capitol', '2828 Zuni', '110 Roy'],
    last_updated: 'February 5, 2025',
  },
];
