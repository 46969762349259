<!-- AppButton.vue -->
<script setup>
const props = defineProps({
  label: String,
  isCheck: Boolean,
  isChecked: Boolean,
  isLoading: Boolean,
  clicked: Function,
  outlined_btn: {
    type: Boolean,
    default: false,
  },
  toggle_btn: {
    type: Boolean,
    default: false,
  },
  alt_btn: {
    type: Boolean,
    default: false,
  },
  full_btn: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clicked']);

function handleClick() {
  emit('clicked', props.label);
}
</script>

<template>
  <button
    :class="{ 'btn--outline': outlined_btn, 'btn--toggle visible-mobile': toggle_btn, 'btn--alt': alt_btn, 'btn--full': full_btn }"
    class="btn"
    @click="handleClick"
  >
    <span v-if="isLoading" class="spinner"></span>
    <template v-else>
      <slot />
      <div class="label-style">{{ label }}</div>
    </template>
  </button>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.btn:hover {
  background-color: red;
  background-color: rgba(97, 112, 134, 0.2);
}

.label-style {
  font-size: 1rem;
  font-weight: lighter;
}
/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
  display: inline-block;
  border: none;
  background-color: var(--primary-color);
  background: var(--primary-gradient);
  background-size: 100%;
  border-radius: 12px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.019em;
  padding: 13px 20px;
  min-width: 120px;
  min-height: 45px;
  transition: all 0.4s;

  &--full {
    width: 100%;
  }

  &--alt {
    background: none;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    min-width: 45px;
    height: 44px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.is-active {
      border-color: var(--primary-color);
    }
  }

  &--outline {
    background: none;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 12px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: -0.019em;
    // padding: 13px 20px;
    // min-height: 45px;
    transition: all 0.4s;

    @include large-desktop {
      font-size: 16px;
      padding: 12px 10px;
    }

    &.is-active {
      border-color: var(--primary-color);
    }
  }

  &--settings {
    padding: 0;
    border: none;
    width: 42px;
    min-width: 42px;
    height: 42px;
  }
}

.btn--toggle {
  background: var(--page-bg);
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 9px;
  font-size: 9px;
  padding: 2px 14px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
  min-height: auto;
  min-width: auto;

  @media screen and (max-width: 425px) {
  }
}

.list-buttons {
  .btn--alt {
    display: block;
  }
}

.btn-group--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--primary-text-color);
}

.btn-group--left {
  border-right: 1px solid var(--primary-text-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* ------------------------------------------------------------ *\
	Button Check
\* ------------------------------------------------------------ */

.btn-check {
  font-size: 16px;
  background: none;
  border: none;
  position: relative;
  padding: 7px 7px 7px 41px;
  border-radius: 5px;
  display: block;
  width: 100%;
  text-align: left;
  min-height: 10px;
  transition:
    background 0.4s,
    color 0.4s;

  @include small-desktop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: var(--property-metric-bg);
    background: linear-gradient(90deg, var(--property-metric-bg) 85%, var(--dropdown-bg) 100%);
    // color: #fff;

    span {
      border-color: var(--primary-color);

      &.checked {
        border-color: var(--primary-color);
      }
    }
  }

  span {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border: var(--border-size) solid var(--secondary-color);
    border-radius: 5px;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.checked {
      border-color: var(--primary-color);
      background: var(--primary-color);
    }
  }
}

.btn-check--list {
  font-size: 16px;
  background: none;
  border: none;
  position: relative;
  padding: 7px;
  border-radius: 5px;
  display: block;
  width: 100%;
  text-align: left;
  transition:
    background 0.4s,
    color 0.4s;

  /*  Small Desktop  */

  @include small-desktop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: rgba(97, 112, 134, 0.2);
    // color: #fff;

    span {
      border-color: var(--primary-color);

      &.checked {
        border-color: var(--primary-color);
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Spinner
\* ------------------------------------------------------------ */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  transition:
    all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1),
    opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.spinner {
  &:before {
    content: '';
    box-sizing: border-box;
    position: relative;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    height: 18px;
    border-radius: 50%;
    border-top: var(--border-size) solid #fff;
    border-right: var(--border-size) solid transparent;
    animation: spinner 0.6s linear infinite;
    transition:
      all 0.4s cubic-bezier(0.25, 0.8, 0.5, 1),
      opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  span {
    transition: opacity 0.3s;
  }
}
</style>
