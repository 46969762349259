<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core/index';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppIconEdit from '@/components/svg/AppIconEdit.vue';
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';
import { useDashboardsStore } from '@/stores/DashboardsStore';

const dashboardsStore = useDashboardsStore();

// Dots menu logic
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked() {
  isOverlayVisible.value = false;
}

// The list of menu options for the overlay
const menuOptions = ['share-link', 'export-to-pdf', 'make-recurring', 'duplicate', 'delete'];

// For referencing in the template
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <div class="left-side-container">
        <div>
          <h3 class="page-title">Dashboards</h3>
        </div>
      </div>

      <!-- Right side container -->
      <div class="styles_pageHeader__icon-container">
        <!-- If a dashboard is selected and loaded, show the 'Edit' / 'Dots' menus -->
        <template v-if="dashboardsStore.selectedDashboard && dashboardsStore.layoutLoaded">
          <div class="top-section" v-if="dashboardsStore.isEditMode">
            <AppButton outlined_btn @click="dashboardsStore.updateDashboardAndOptions" class="save-btn"> Save </AppButton>
          </div>
          <!-- Dots menu -->
          <div ref="target">
            <AppIconBoxWrapper @click="toggleOverlay">
              <AppIconDotsVertical />
            </AppIconBoxWrapper>
            <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="menuOptions" bodyWidth="150px">
              <template #share-link>
                <div>Share link</div>
              </template>
              <template #export-to-pdf>
                <div>Export to PDF</div>
              </template>
              <template #make-recurring>
                <div>Make recurring</div>
              </template>
              <template #duplicate>
                <div>Duplicate</div>
              </template>
              <template #delete>
                <div @click="dashboardsStore.removeDashboard">Delete</div>
              </template>
            </AppOverlayMenu>
          </div>

          <!-- Edit icon -->
          <AppIconBoxWrapper @click="dashboardsStore.toggleEditMode">
            <AppIconEdit />
          </AppIconBoxWrapper>
        </template>

        <!-- Otherwise, show "Add Dashboard" icon -->
        <template v-else>
          <AppActionButton @click="dashboardsStore.createNewDashboard" label="New Dashboard">
            <AppIconAddAlt />
          </AppActionButton>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

// Common container styling
.styles_pageHeader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Fade classes for the breadcrumbs
.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}

.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}

// Left-side container
.left-side-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

// If in edit mode, show 'Save' button
.top-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

// Input styling for the dashboard name (if in edit mode)
.dashboard-input {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 19px;
  font-weight: 600;
  color: var(--primary-text-color);
  min-width: 260px;
}

.save-btn {
  padding: 5px;
  min-height: 33px;
  border-radius: 7px;
  transition: all 0.3s ease;

  &:hover {
    border-color: var(--primary-color);
  }
}
</style>
