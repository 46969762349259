<script setup>
// Imports...
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useAuthStore } from './stores/AuthStore';
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import { useRoute } from 'vue-router';
import { useGlobalStore } from '@/stores/GlobalStore';
import createRouterInstance from './router.js';
import DashboardsSideBar from '@/components/dashboard/SideBar.vue';
import { useThemeStore } from '@/stores/ThemeStore';
import { useDashboardsStore } from '@/stores/DashboardsStore';
// Logic...
const dashboardStore = useDashboardsStore();
const globalStore = useGlobalStore();
const authStore = useAuthStore();
const themeStore = useThemeStore();
const themes = ['Dark', 'Light', 'Matrix', 'Dyverse'];

onMounted(() => {
  // Theme related logic to set the theme based on the stored value in localStorage
  const storedTheme = localStorage.getItem('theme');
  if (storedTheme && themes.includes(storedTheme)) {
    themeStore.setSelectedTheme(storedTheme);
    document.documentElement.setAttribute('data-theme', storedTheme.toLowerCase());
  } else {
    themeStore.setInitialTheme(themes[0]);
  }
  // End of theme related logic...
  // Auth store logic to renew the token every hour
  const intervalId = setInterval(
    () => {
      if (authStore.isLoggedIn()) {
        authStore.renewToken();
      }
      // End of auth store logic...
    },
    1000 * 60 * 60
  ); // Renew every hour

  onUnmounted(() => {
    clearInterval(intervalId); // Clear the interval when the component is unmounted
  });
});

// Logic for the current route name for the top filter visibility
const route = useRoute();
const isLoginPage = computed(() => route.path === '/');
const router = createRouterInstance();
let currentRouteName = ref(router.resolve(window.location.pathname).name);

watch(
  () => route.path,
  () => {
    currentRouteName.value = router.resolve(window.location.pathname).name;
  }
);
// End of logic...
</script>

<template>
  <div class="app-container">
    <div class="header-container">
      <TheHeader v-if="!isLoginPage" />
      <router-view name="topFilter" v-if="globalStore.topFilterVisibility[currentRouteName]" />
      <router-view name="pageHeader" />
    </div>
    <div>
      <TheChat />
    </div>
    <div class="body-container">
      <div id="app-body">
        <router-view />
      </div>
      <DashboardsSideBar v-if="dashboardStore.isEditMode" />
    </div>
    <div class="footer-container">
      <TheFooter />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/themes.scss';

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

// I don't think we need this anymore
//.header-container {
//  position: sticky;
//  top: 0;
//  z-index: 1000;
//}

.body-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  // min-height: 75vh; // Removed this because it was cutting off the bottom of the page in some cases. Needs testing.
  overflow: hidden;
}

#app-body {
  flex-grow: 1;
  padding: 20px 20px 0px 20px;
  overflow-y: auto;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: all 0.3s ease;
    border-left: 0 solid var(--property-metric-bg);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }

  &.visible-top-filter::-webkit-scrollbar-track {
    margin-top: 223px;
  }

  &.hidden-top-filter::-webkit-scrollbar-track {
    margin-top: 138px;
  }
}

.footer-container {
  position: sticky;
  bottom: 0;
  z-index: 1000;
}
</style>
