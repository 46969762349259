<!-- dashboard/cardlayout.vue -->
<script setup>
import { ref, computed } from 'vue';
import { useCursorDropdown } from '@/composables/useCursorDropdown';
import { useSettingsStore } from '@/stores/SettingsStore';
import AppTooltip from '@/components/ui/AppTooltip.vue';
import DashboardLinks from '@/components/dashboard/card-components/DashboardLinks.vue';

const menuOptions = [
  { label: 'View', value: 'view' },
  { label: 'Customize', value: 'customize' },
];

// Store
const settingsStore = useSettingsStore();
const integrations = computed(() => settingsStore.allIntegrations);

const filteredIntegrations = computed(() => {
  return integrations.value.filter((integration) => integration.integration_sync_status === true);
});

// Composable for cursor-positioned dropdown
const { showOverlay, userPosition, dropdownTarget, toggleOverlay } = useCursorDropdown();

// Props
const props = defineProps({
  title: { type: String, required: true },
  lastEdited: { type: String, required: true },
  buttonText: { type: String, required: true },
  buttonType: { type: String, default: 'view' },
  dashboardId: { type: Number, required: true },
});

function onOptionSelected(optionValue) {
  if (optionValue === 'view') {
    // e.g., navigate or handle "View" logic
    console.log('User selected: View');
  } else if (optionValue === 'customize') {
    // e.g., open a settings modal
    console.log('User selected: Customize');
  }
}
</script>

<template>
  <div class="dashboard-card" @click="($event) => toggleOverlay($event)" ref="dropdownTarget">
    <div class="dashboard-header">
      <div class="dashboard-info">
        <span class="dashboard-title">{{ title }}</span>
        <span class="dashboard-last-edited">{{ lastEdited }}</span>
      </div>
    </div>

    <div class="dashboard-footer">
      <div v-if="filteredIntegrations.length > 0" class="footer-content">
        <div v-for="integration in filteredIntegrations" :key="integration.id">
          <AppTooltip>
            <component :is="integration.integration_icon" />
            <template #text>
              <span class="description">
                {{ integration.sync_message }}
              </span>
            </template>
          </AppTooltip>
        </div>
      </div>
      <div v-else>
        <p class="no-connections">
          This dashboard does not contain any integrations.
          <a href="/settings">Setup connections</a>
        </p>
      </div>
    </div>

    <DashboardLinks
      v-if="showOverlay"
      :dashboardId="dashboardId"
      :options="menuOptions"
      @option-select="onOptionSelected"
      :style="{
        position: 'fixed',
        top: `${userPosition.y - 25}px`,
        left: `${userPosition.x + 75}px`,
        zIndex: userPosition.z,
      }"
    />
  </div>
</template>

<style scoped lang="scss">
.dashboard-card {
  background-color: var(--property-card-bg);
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;

  &:hover {
    border: var(--border-size) solid var(--primary-color);
    cursor: pointer;
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0px 0px 10px;
}

.dashboard-info {
  display: flex;
  flex-direction: column;

  .dashboard-last-edited {
    color: var(--muted-text);
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    margin-top: 3px;
  }
}

.dashboard-title {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.view-button,
.add-button {
  color: var(--primary-text-color);
  font-family: Lato;
  font-size: 10.033px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: #ffffff;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-button:hover,
.add-button:hover {
  background-color: #218838;
}

.dashboard-footer {
  display: flex;
  gap: 10px;
  padding: 10px 16px 7px;
  color: var(--secondary-color);
  background-color: var(--property-info-bg);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .footer-content {
    display: flex;
    gap: 10px;
  }

  svg {
    width: 16px;
    height: 16px;
    opacity: 0.5; /* Default opacity */
    filter: grayscale(75%); /* Desaturates the icon */
    transition:
      opacity 0.3s ease-in-out,
      filter 0.3s ease-in-out; /* Smooth effect */
  }

  /* When hovering over a specific SVG */
  svg:hover {
    opacity: 1; /* Full visibility */
    filter: grayscale(0%); /* Full color */
  }

  .no-connections {
    font-size: 12px;
    opacity: 0.5;
  }
}
</style>
