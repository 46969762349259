<script setup></script>

<template>
  <div class="icon-box">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.icon-box {
  width: 34px;
  height: 33px;
  padding: 5px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
    cursor: pointer;

    :deep(.icon-color) {
      fill: var(--primary-color);
    }
  }
}
</style>
