<!-- DashboardsSideBar.vue -->
<script setup>
// Imports...
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import useSearch from '@/composables/useSearch';
import TheDashboardSearch from '@/components/ui/search/TheDashboardSearch.vue';
import AppTooltip from '@/components/ui/AppTooltip.vue';
import AppIconInfo from '@/components/svg/AppIconInfo.vue';
import { useDashboardsStore } from '@/stores/DashboardsStore';
import { useThrottleFn, useVirtualList } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid'; // Import UUID
// you can import from 'lodash-es' or implement it by yourself
// Logic...

const dashboardsStore = useDashboardsStore();

onMounted(() => {
  document.addEventListener('dragover', syncMousePosition);
});

onBeforeUnmount(() => {
  document.removeEventListener('dragover', syncMousePosition);
});

const mouseAt = { x: -1, y: -1 };
const dropId = 'drop';
const dragItem = { x: -1, y: -1, w: 2, h: 2, i: '' };

const syncMousePosition = useThrottleFn((event) => {
  mouseAt.x = event.clientX - 200;
  mouseAt.y = event.clientY;
}, 16);

let isDragging = false;

const drag = (event) => {
  if (!isDragging) {
    isDragging = true;
    requestAnimationFrame(() => {
      dashboardsStore.personalDragging = true;
      const parentRect = dashboardsStore.wrapper?.getBoundingClientRect();

      if (!parentRect || !dashboardsStore.gridLayout) {
        isDragging = false; // Reset the flag if layout is invalid
        return;
      }

      const mouseInGrid = mouseAt.x > parentRect.left && mouseAt.x < parentRect.right && mouseAt.y > parentRect.top && mouseAt.y < parentRect.bottom;

      // Get the data id of the dragged widget
      const dragId = event.target.getAttribute('data-id');
      const draggedItem = dashboardsStore.widgets.find((item) => item.i === dragId);

      if (mouseInGrid && !dashboardsStore.dashboardData.layout.find((item) => item.i === dropId)) {
        dashboardsStore.dashboardData.layout.push({
          x: (dashboardsStore.dashboardData.layout.length * 2) % 12,
          y: dashboardsStore.dashboardData.layout.length + 12,
          w: draggedItem.w,
          h: draggedItem.h,
          i: dropId,
          component: draggedItem.component,
        });
      }

      const index = dashboardsStore.dashboardData.layout.findIndex((item) => item.i === dropId);

      if (index !== -1) {
        const item = dashboardsStore.gridLayout.getItem(dropId);

        if (!item) {
          isDragging = false;
          return;
        }

        try {
          item.wrapper.style.display = 'none';
        } catch (e) {
          console.error(e);
        }

        Object.assign(item.state, {
          top: mouseAt.y - parentRect.top,
          left: mouseAt.x - parentRect.left,
        });
        const newPos = item.calcXY(mouseAt.y - parentRect.top, mouseAt.x - parentRect.left);

        if (mouseInGrid) {
          const dragId = event.target.getAttribute('data-id');
          const draggedItem = dashboardsStore.widgets.find((item) => item.i === dragId);
          dragItem.w = draggedItem.w;
          dragItem.h = draggedItem.h;
          dashboardsStore.gridLayout.dragEvent('dragstart', dropId, newPos.x, newPos.y, dragItem.h, dragItem.w);
          dragItem.i = String(index);
          dragItem.x = dashboardsStore.dashboardData.layout[index].x;
          dragItem.y = dashboardsStore.dashboardData.layout[index].y;
          dragItem.component = draggedItem.component;
          dragItem.minW = draggedItem.minW;
          dragItem.minH = draggedItem.minH;
          dragItem.maxH = draggedItem.maxH;
        } else {
          dashboardsStore.gridLayout.dragEvent('dragend', dropId, newPos.x, newPos.y, dragItem.h, dragItem.w);
          dashboardsStore.dashboardData.layout = dashboardsStore.dashboardData.layout.filter((item) => item.i !== dropId);
        }
      }

      isDragging = false; // Reset the flag to allow another frame
    });
  }
};

function dragEnd() {
  const parentRect = dashboardsStore.wrapper?.getBoundingClientRect();

  if (!parentRect || !dashboardsStore.gridLayout) return;

  const mouseInGrid = mouseAt.x > parentRect.left && mouseAt.x < parentRect.right && mouseAt.y > parentRect.top && mouseAt.y < parentRect.bottom;

  if (mouseInGrid) {
    dashboardsStore.gridLayout.dragEvent('dragend', dropId, dragItem.x, dragItem.y, dragItem.h, dragItem.w);
    dashboardsStore.dashboardData.layout = dashboardsStore.dashboardData.layout.filter((item) => item.i !== dropId);
  } else {
    return;
  }

  dashboardsStore.dashboardData.layout.push({
    x: dragItem.x,
    y: dragItem.y,
    w: dragItem.w,
    h: dragItem.h,
    i: uuidv4(),
    minW: dragItem.minW,
    minH: dragItem.minH,
    maxH: dragItem.maxH,
    component: dragItem.component,
  });
  dashboardsStore.gridLayout.dragEvent('dragend', dragItem.i, dragItem.x, dragItem.y, dragItem.h, dragItem.w);

  const item = dashboardsStore.gridLayout.getItem(dropId);

  if (!item) return;

  try {
    item.wrapper.style.display = '';
  } catch (e) {
    console.error(e);
  }
  dashboardsStore.personalDragging = false;
}

const filter = ref('View all');
const { searchValue, filteredOptions } = useSearch(dashboardsStore.widgets);

const filteredWidgets = computed(() => {
  return filteredOptions.value.filter((widget) => {
    return (
      (widget.title.toLowerCase().includes(searchValue.value.toLowerCase()) ||
        widget.type.toLowerCase().includes(searchValue.value.toLowerCase()) ||
        widget.integration.toLowerCase().includes(searchValue.value.toLowerCase())) &&
      (widget.type === filter.value || filter.value === 'View all' || widget.integration === filter.value)
    );
  });
});

// virtual list
const filteredWidgetsRef = ref(filteredWidgets.value);

// Watch for changes in the computed property
watch(
  () => filteredWidgets.value,
  (newVal) => {
    filteredWidgetsRef.value = newVal;
  },
  { deep: true }
);

const { list, containerProps, wrapperProps } = useVirtualList(filteredWidgetsRef, {
  itemHeight: 165,
  overscan: 10,
});
</script>

<template>
  <div class="sidebar-container">
    <div class="dashboards-sidebar-search-container">
      <TheDashboardSearch placeholder="Search..." v-model="searchValue" @filter-changed="filter = $event" />
    </div>
    <div v-bind="containerProps" class="use-virtual-list">
      <div v-bind="wrapperProps">
        <div class="sidebar-card-container">
          <div class="card" v-for="widget in list" draggable="true" @drag="drag" @dragend="dragEnd" :key="widget.data.i" :data-id="widget.data.i">
            <div class="preview">
              <div class="preview-content">
                <div class="icon-container" v-if="widget.data.icon">
                  <component :is="widget.data.icon" class="icon" />
                </div>
                <div class="chart-container">
                  <component :is="widget.data.preview" class="chart" />
                </div>
              </div>
            </div>
            <div class="title">{{ widget.data.title }}</div>
            <div class="content-container">
              <div class="integration">{{ widget.data.integration }}</div>
            </div>
            <div class="info-icon">
              <AppTooltip>
                <AppIconInfo />
                <template #text>
                  <span class="description">{{ widget.data.description }}</span>
                </template>
              </AppTooltip>
            </div>
          </div>
          <div v-if="filteredWidgets.length === 0">No results found...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/themes';

.draggable-element {
  cursor: grab;
  margin: 0;
  padding: 0;
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 391px;
  border-left: 1px solid var(--secondary-color);
}

.dashboards-sidebar-search-container {
  padding: 10px;
  border-bottom: 1px solid var(--secondary-color);
}

.data-source-selector {
  width: 150px;
}

.report-type-selector {
  width: 150px;
}

.use-virtual-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 98%;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: var(--property-card-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: all 0.3s ease;
    border-radius: 0;
    border-left: 0 solid var(--property-metric-bg);
  }

  &::-webkit-scrollbar-thumb:hover {
    transition: all 0.3s ease;
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.sidebar-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 20px 20px 55px 20px;
  width: 100%;
}

.card {
  margin: 5px 0;
  width: 185px;
  height: 165px;
  border-radius: 10px;
  border: var(--border-size) solid var(--secondary-color);
  background-color: var(--property-info-bg);
  transition: 0.4s;
  flex: 0 0 auto;

  &:hover {
    cursor: grab;
    border-color: var(--primary-color);
  }
}

.preview {
  width: 100%;
  height: 85px;
  flex-shrink: 0;
  border-radius: 10px;
}

.preview-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 10px 10px 0 0;
  background-color: var(--property-card-bg);
  width: 100%;
  height: 85px;
}

.icon-container {
  position: absolute;
  top: 9px;
  left: 9px;
  display: flex;
  width: 25px;
  height: 25px;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 7.68px;
  flex-shrink: 0;
  border-radius: 39.769px;
  border: 0.568px solid #fff;
  background: #fff;
}

.icon {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

.chart-container {
  display: flex;
  width: 125px;
  margin-left: 8px;
  height: 85px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  svg {
    height: auto;
  }
}

.pill-container {
  position: absolute;
  top: 12px;
  right: 11px;
  display: flex;
  width: 70px;
  height: 20px;
  justify-content: center;
  align-items: center;
  gap: 3.915px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0.392px solid var(--secondary-color);
  background-color: var(--property-info-bg);
}

.pill-text {
  color: var(--secondary-color);
  font-family: Lato, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.content-container {
  display: inline-flex;
  gap: 5px;
  padding-top: 5px;
  padding-left: 9px;
}

.title {
  color: var(--primary-text-color);
  font-family: Lato, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 5px;
  padding-left: 10px;
}

.integration {
  color: var(--secondary-color);
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.description {
  color: var(--primary-text-color);
  font-family: Lato, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  width: 152px;
}

.info-icon {
  position: relative;
  bottom: -9px;
  left: 10px;
}

.info-icon svg {
  height: 19px;
  width: 19px;
}
</style>
