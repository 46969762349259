<!-- ManageGroupsModal.vue -->
<script setup>
import { ref, computed, watch } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { groupTypeOptions } from '@/data/groupsData';
import AppModal from '@/components/ui/AppModal.vue';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import AppListPills from '@/components/ui/AppListPills.vue';
import AppSkeletonPlaceholder from '@/components/ui/AppSkeletonPlaceholder.vue';

const modalStore = useModalStore();
const modalData = computed(() => modalStore.modalData);

// Computed modal title using the group's name from modalData
const modalTitle = computed(() => {
  return modalData.value?.groupName || '';
});

// Computed pill text for the header, using the group's type
const modalGroupType = computed(() => {
  return modalData.value?.groupType || '';
});

// Loading states
const saveLoading = ref(false);
const isLoading = computed(() => modalStore.modalLoading);

// Local state for properties and type
const selectedProperties = ref([]);
const selectedType = ref('All');

// Portfolio-specific reactive fields
const portfolioField1 = ref('');
const portfolioField2 = ref('');
const portfolioField3 = ref('');

// Initialize selectedProperties when modalData changes
watch(
  () => modalData.value.properties,
  (newProperties) => {
    selectedProperties.value = [...(newProperties || [])];
  },
  { immediate: true }
);

// Set the selected type based on modalData, defaulting to 'All'
watch(
  () => modalData.value.groupType,
  (newGroupType) => {
    selectedType.value = newGroupType || 'All';
  },
  { immediate: true }
);

// Clear portfolio-specific fields if the type changes from 'Portfolio'
watch(selectedType, (newType) => {
  if (newType !== 'Portfolio') {
    portfolioField1.value = '';
    portfolioField2.value = '';
  }
});

function handleSave() {
  saveLoading.value = true;
  modalStore.simulateSave('manageGroupsModal');
  setTimeout(() => {
    saveLoading.value = false;
  }, 1000);
}
</script>

<template>
  <teleport to="body">
    <AppModal :title="modalTitle" modalId="manageGroupsModal">
      <!-- Header Pill: Shows the group type if available -->
      <template #modalHeaderExtra>
        <span v-if="modalGroupType" class="pill">{{ modalGroupType }}</span>
      </template>

      <template #modalBody>
        <div v-if="isLoading" class="loading-state">
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="40px" />
          </div>
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="180px" />
          </div>
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="180px" />
          </div>
          <div class="placeholder-block">
            <AppSkeletonPlaceholder width="100%" height="180px" />
          </div>
        </div>

        <div v-else>
          <!-- Group type selector: defaults to the current group's type -->
          <AppSingleSelector title="Type" placeholder="Select a group type" :options="groupTypeOptions" v-model="selectedType" />

          <!-- Portfolio-specific fields -->
          <div v-if="selectedType === 'Portfolio'">
            <AppListPills
              title="Users"
              placeholder="Manage user access to this group"
              :listItems="allProperties"
              v-model="portfolioField1"
              :useRawItem="true"
            />
            <AppListPills
              title="Source Channels"
              placeholder="Select or add source channels"
              :listItems="allProperties"
              v-model="portfolioField2"
              :useRawItem="true"
            />
            <AppListPills
              title="Categories"
              placeholder="Select or add categories"
              :listItems="allProperties"
              v-model="portfolioField3"
              :useRawItem="true"
            />
          </div>

          <!-- Properties pills selector -->
          <AppListPills title="Properties" :listItems="selectedProperties" v-model="selectedProperties" :useRawItem="true" />
        </div>
      </template>

      <template #modalFooter>
        <AppButton label="Save" :isLoading="saveLoading" @clicked="handleSave" />
      </template>
    </AppModal>
  </teleport>
</template>

<style scoped lang="scss">
.loading-state {
  padding: 20px;
}

.placeholder-block {
  background: var(--property-card-bg);
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
}

.pill {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  margin: 0;
  padding: 5px 15px;
  display: inline-block;
  font-size: 0.65em;
  color: var(--primary-text-color);
}
</style>
