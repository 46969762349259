<!-- GroupsTopFilterSection.vue -->
<script setup>
// imports
import AppIconAngleDownPair from '@/components/svg/AppIconAngleDownPair.vue';
import { groupTypeOptions } from '@/data/groupsData';
import AppButton from '@/components/ui/buttons/AppButton.vue';
import AppSingleSelector from '@/components/ui/selects/AppSingleSelector.vue';
import { useGlobalStore } from '@/stores/GlobalStore';
import { useGroupsStore } from '@/stores/GroupsStore';
import AppVisibleFilterWrapper from '@/components/ui/AppVisibleFilterWrapper.vue';
import AppKeywordSearch from '@/components/ui/search/AppKeywordSearch.vue';

// Store instances
const globalStore = useGlobalStore();
const groupsStore = useGroupsStore();

// Handle visibility for the top filter navbar inputs and selectors
const handleClick = () => {
  globalStore.toggleVisibleFilter();
};
</script>

<template>
  <div class="styles_topFilter__container">
    <div class="grid">
      <div class="flex-auto">
        <AppKeywordSearch label="Keywords" placeholder="Search group name" v-model="groupsStore.searchQuery" />
      </div>
      <AppVisibleFilterWrapper>
        <AppSingleSelector
          bodyWidth="300px"
          title="Group Type"
          :options="groupTypeOptions"
          v-model="groupsStore.selectedGroupType"
          placeholder="Select an option"
        />
      </AppVisibleFilterWrapper>
      <!-- filter button-->
      <div class="filter-button-container">
        <div v-if="groupsStore.isFilterApplied" class="filter-clear-all" @click="groupsStore.resetFilters">Reset</div>
        <div v-else class="filter-clear-all-placeholder"></div>
        <AppButton
          label="Search"
          :isLoading="groupsStore.filterLoading"
          @clicked="groupsStore.applyFilters"
          :class="[!groupsStore.isFilterApplied, 'filter-button-bottom']"
        />
      </div>
    </div>
    <AppButton toggle_btn @click="handleClick">
      <AppIconAngleDownPair />
    </AppButton>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/settings/mixins';
@import '../../styles/settings/themes';

.grid {
  display: flex;
  align-items: center; // Vertically center items
  flex-wrap: wrap; // Allow wrapping
  gap: 15px;
}

.flex-auto {
  flex: 1 1 auto; // Flex item can grow and shrink but is initially sized to content
}

.flex-basis {
  flex-basis: content;
}

.filter-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-clear-all,
.filter-clear-all-placeholder {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  text-decoration: underline;
  height: 15px; // Adjust to match the height of the reset button
  visibility: hidden; // Hide the placeholder but keep its space
}

.filter-clear-all {
  visibility: visible; // Make the actual button visible
  text-decoration: underline;

  &:hover {
    color: var(--primary-text-color);
    cursor: pointer;
  }

  &:active {
    opacity: 0.7; // Adjust as needed
  }
}

@media screen and (min-width: 768px) {
  .grid {
    display: flex;
  }
}

.visible-mobile {
  display: none;
  @include tablet {
    display: block;
  }
}
</style>
