<template>
  <div class="search-container">
    <div class="search-bar">
      <div class="sparkle-icon">
        <!-- Might be moved to a separate component -->
        <svg width="30" height="30" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M55.719 36.5527C38.8678 38.8551 35.872 41.7798 33.6252 58.8301C33.5503 59.3901 32.5766 59.3901 32.5018 58.8301C30.2549 41.7798 27.2591 38.9173 10.4079 36.5527C9.73383 36.4905 9.73383 35.6815 10.4079 35.6193C27.2591 33.3169 30.2549 30.4544 32.5018 13.4041C32.5766 12.8441 33.5503 12.8441 33.6252 13.4041C35.872 30.4544 38.8678 33.2546 55.719 35.6193C56.3182 35.6815 56.3182 36.4282 55.719 36.5527Z"
            fill="#00FF0A"
            class="sparkle-path"
          />
          <path
            d="M60.1224 17.7559C54.1309 18.5234 53.0657 19.4983 52.2668 25.1817C52.2402 25.3684 51.894 25.3684 51.8674 25.1817C51.0685 19.4983 50.0033 18.5441 44.0118 17.7559C43.7721 17.7352 43.7721 17.4655 44.0118 17.4448C50.0033 16.6773 51.0685 15.7232 51.8674 10.0397C51.894 9.85305 52.2402 9.85305 52.2668 10.0397C53.0657 15.7232 54.1309 16.6566 60.1224 17.4448C60.3354 17.4655 60.3354 17.7144 60.1224 17.7559Z"
            fill="#00FF0A"
            class="sparkle-path"
          />
        </svg>
      </div>

      <input type="text" placeholder="Ask me anything about this page..." v-model="searchQuery" />

      <button @click="handleSubmit" class="kai-submit-button">
        <!-- Might be moved to a separate component -->
        <svg width="30" height="31" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg" class="default-send-icon">
          <path
            d="M33.9039 35.3814L25.3365 35.3814M52.8336 34.409C53.0159 34.4977 53.1695 34.6359 53.2769 34.8078C53.3843 34.9797 53.4412 35.1784 53.441 35.3811C53.4409 35.5838 53.3837 35.7824 53.2761 35.9541C53.1684 36.1259 53.0146 36.2639 52.8322 36.3523L20.1989 52.0678C20.0038 52.1617 19.7849 52.1948 19.5708 52.1628C19.3566 52.1308 19.157 52.0352 18.9978 51.8884C18.8387 51.7415 18.7273 51.5502 18.6782 51.3393C18.6291 51.1285 18.6445 50.9077 18.7224 50.7056L24.4669 35.7683C24.5627 35.5192 24.5627 35.2435 24.4669 34.9944L18.7239 20.0557C18.6466 19.8539 18.6317 19.6335 18.681 19.4232C18.7303 19.2128 18.8415 19.022 19.0003 18.8755C19.1591 18.729 19.3582 18.6335 19.5719 18.6013C19.7855 18.5691 20.0039 18.6017 20.1989 18.6949L52.8336 34.409Z"
            class="send-path default-icon"
            stroke-width="3.45362"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <!-- Might be moved to a separate component -->
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 27 26" fill="none" class="alt-send-icon">
          <rect x="0.685547" y="0.0085144" width="26.3056" height="25.9298" rx="3.75795" fill="#152533" />
          <path
            d="M13.0302 12.9741L9.88869 12.9741M19.9715 12.6175C20.0383 12.65 20.0947 12.7007 20.134 12.7638C20.1734 12.8268 20.1943 12.8996 20.1942 12.974C20.1942 13.0483 20.1732 13.1211 20.1337 13.1841C20.0943 13.2471 20.0379 13.2977 19.971 13.3301L8.0048 19.0928C7.93325 19.1272 7.85301 19.1393 7.77448 19.1276C7.69595 19.1159 7.62276 19.0808 7.5644 19.027C7.50604 18.9731 7.46521 18.903 7.4472 18.8256C7.42919 18.7483 7.43484 18.6674 7.46341 18.5933L9.56982 13.116C9.60493 13.0246 9.60493 12.9235 9.56982 12.8322L7.46393 7.35436C7.43561 7.28036 7.43014 7.19957 7.44821 7.12243C7.46628 7.04528 7.50706 6.97533 7.5653 6.92161C7.62353 6.86788 7.69654 6.83285 7.77489 6.82105C7.85323 6.80924 7.93332 6.8212 8.0048 6.85538L19.9715 12.6175Z"
            stroke="white"
            stroke-width="1.26639"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const searchQuery = ref('');

const handleSubmit = () => {
  console.log('Search query:', searchQuery.value);
};
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 0;
}

.search-bar {
  width: 100%;
  background: #121e2a;
  backdrop-filter: blur(8px);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  gap: 8px;
  min-width: 0;
  transition: all 0.2s ease;
  border: 1.08px solid transparent;

  .sparkle-path {
    fill: #00ff0a;
    transition: fill 0.2s ease;
  }

  .send-path {
    stroke: rgba(255, 255, 255, 0.3);
    transition:
      stroke 0.2s ease,
      opacity 0.2s ease;
  }

  .alt-send-icon {
    transition: all 0.2s ease;
    opacity: 0;
    display: none;
  }

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible,
  &:target {
    border: 1.08px solid #00ff0a;
    box-shadow: 0px 0px 50.772px 5.96px rgba(0, 255, 10, 0.2);

    .sparkle-path {
      fill: #fff;
    }

    .send-path {
      stroke: #fff;
    }

    .default-send-icon {
      opacity: 0;
      display: 0;
    }

    .alt-send-icon {
      opacity: 1;
      display: block;
    }
  }

  &:hover {
    border: 1.08px solid #00ff0a;
  }
}

.sparkle-icon {
  display: flex;
  color: #00ff0a;
  animation: pulse 2s infinite;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
}

input {
  flex: 1;
  background: transparent;
  border: none;
  color: #e5e7eb;
  font-size: 14px;
  outline: none;
  min-width: 0;

  &::placeholder {
    color: rgba(156, 163, 175, 0.7);
    font-size: 14px;
  }
}

button {
  display: flex;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>
