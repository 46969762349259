<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { KaiWebSocketService } from '@/services/kaiWebSocket';
import AppIconClose from '@/components/svg/AppIconClose.vue';
import { useModalStore } from '@/stores/ModalStore';
import DatePicker from '@/components/ui/AppDatePicker.vue';
import AppPropertiesSelector from '@/components/ui/selects/AppPropertiesSelector.vue';

const props = defineProps({
  initialMessage: {
    type: Object,
    default: null,
  },
});

const messages = reactive([]);
const chatInput = ref('');
const isLoading = ref(false);
const wsService = ref(null);
const connectionStatus = ref('disconnected');
const messagesContainer = ref(null);
const modalStore = useModalStore();

const closeModal = () => {
  modalStore.closeModal('chat-modal');
};

const scrollToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
};

watch(
  messages,
  () => {
    nextTick(() => {
      scrollToBottom();
    });
  },
  { deep: true }
);

watch(
  () => props.initialMessage,
  (newMessage) => {
    if (newMessage && !messages.some((msg) => msg.id === newMessage.id)) {
      messages.push(newMessage);
    }
  },
  { immediate: true }
);

onMounted(async () => {
  wsService.value = new KaiWebSocketService();

  wsService.value.onMessage((data) => {
    if (data.status) {
      console.log('Status update:', data.status);
      return;
    }

    if (data.response !== undefined) {
      const lastMessage = messages[messages.length - 1];

      if (data.done && !data.response.trim()) {
        isLoading.value = false;
        return;
      }

      if (lastMessage && lastMessage.sender === 'bot' && !data.done) {
        lastMessage.text += data.response;
      } else if (data.response.trim()) {
        messages.push({
          id: uuidv4(),
          text: data.response,
          sender: 'bot',
        });
      }

      if (data.done) {
        isLoading.value = false;
      }
    }
  });

  wsService.value.onStatus((status) => {
    connectionStatus.value = status;
  });

  await wsService.value.connect();

  if (props.initialMessage?.text) {
    wsService.value.sendMessage(props.initialMessage.text);
  }

  if (props.initialMessage && !messages.some((msg) => msg.id === props.initialMessage.id)) {
    messages.push(props.initialMessage);
  }
});

onUnmounted(() => {
  if (wsService.value) {
    wsService.value.disconnect();
    wsService.value = null;
  }
});

const sendChatMessage = () => {
  const trimmedInput = chatInput.value.trim();
  if (trimmedInput !== '') {
    const userMessage = {
      id: uuidv4(),
      text: trimmedInput,
      sender: 'user',
    };

    messages.push(userMessage);

    if (wsService.value.sendMessage(trimmedInput)) {
      chatInput.value = '';
      isLoading.value = true;
    } else {
      messages.push({
        id: uuidv4(),
        text: 'Error: Not connected to server',
        sender: 'bot',
      });
    }
  }
};
</script>

<template>
  <div v-if="modalStore.isModalOpen('chat-modal')" class="modal-overlay" @click="closeModal">
    <div class="modal-wrapper" @click.stop>
      <div class="modal-sidebar">
        <div class="sidebar-controls">
          <AppPropertiesSelector class="properties-selector" />
          <DatePicker v-model="startDate" class="date-control" placeholder="Date Range" />
          <button class="review-button">Review data</button>
        </div>

        <div class="divider"></div>

        <div class="chat-history">
          <div class="chat-item active">Can you review leasing performa...</div>
          <div class="chat-item">Help me understand bounce rate</div>
          <div class="chat-item">Conversions seem low what can i do</div>
        </div>

        <div class="user-profile">
          <img src="@/assets/svg/ico-users.svg" alt="User Avatar" class="user-avatar" />
          <span class="user-name">John Todero</span>
        </div>
      </div>

      <div class="modal-content">
        <button @click="closeModal" class="close-button">
          <AppIconClose />
        </button>
        <div ref="messagesContainer" class="messages-container">
          <div v-for="message in messages" :key="message.id" :class="['message', message.sender]">
            <div v-if="message.sender === 'bot'" class="bot-avatar">
              <!-- this svg should be updated when frontend picks an icon for search -->
              <svg width="30" height="30" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M55.719 36.5527C38.8678 38.8551 35.872 41.7798 33.6252 58.8301C33.5503 59.3901 32.5766 59.3901 32.5018 58.8301C30.2549 41.7798 27.2591 38.9173 10.4079 36.5527C9.73383 36.4905 9.73383 35.6815 10.4079 35.6193C27.2591 33.3169 30.2549 30.4544 32.5018 13.4041C32.5766 12.8441 33.5503 12.8441 33.6252 13.4041C35.872 30.4544 38.8678 33.2546 55.719 35.6193C56.3182 35.6815 56.3182 36.4282 55.719 36.5527Z"
                  fill="#00FF0A"
                  class="sparkle-path"
                />
                <path
                  d="M60.1224 17.7559C54.1309 18.5234 53.0657 19.4983 52.2668 25.1817C52.2402 25.3684 51.894 25.3684 51.8674 25.1817C51.0685 19.4983 50.0033 18.5441 44.0118 17.7559C43.7721 17.7352 43.7721 17.4655 44.0118 17.4448C50.0033 16.6773 51.0685 15.7232 51.8674 10.0397C51.894 9.85305 52.2402 9.85305 52.2668 10.0397C53.0657 15.7232 54.1309 16.6566 60.1224 17.4448C60.3354 17.4655 60.3354 17.7144 60.1224 17.7559Z"
                  fill="#00FF0A"
                  class="sparkle-path"
                />
              </svg>
            </div>
            <div class="message-content">
              <div class="message-text" :class="{ 'user-message': message.sender === 'user' }">
                {{ message.text }}
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="typing-indicator">
            <!-- this svg should be updated when frontend picks an icon for search -->
            <svg width="30" height="30" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M55.719 36.5527C38.8678 38.8551 35.872 41.7798 33.6252 58.8301C33.5503 59.3901 32.5766 59.3901 32.5018 58.8301C30.2549 41.7798 27.2591 38.9173 10.4079 36.5527C9.73383 36.4905 9.73383 35.6815 10.4079 35.6193C27.2591 33.3169 30.2549 30.4544 32.5018 13.4041C32.5766 12.8441 33.5503 12.8441 33.6252 13.4041C35.872 30.4544 38.8678 33.2546 55.719 35.6193C56.3182 35.6815 56.3182 36.4282 55.719 36.5527Z"
                fill="#00FF0A"
                class="sparkle-path"
              />
              <path
                d="M60.1224 17.7559C54.1309 18.5234 53.0657 19.4983 52.2668 25.1817C52.2402 25.3684 51.894 25.3684 51.8674 25.1817C51.0685 19.4983 50.0033 18.5441 44.0118 17.7559C43.7721 17.7352 43.7721 17.4655 44.0118 17.4448C50.0033 16.6773 51.0685 15.7232 51.8674 10.0397C51.894 9.85305 52.2402 9.85305 52.2668 10.0397C53.0657 15.7232 54.1309 16.6566 60.1224 17.4448C60.3354 17.4655 60.3354 17.7144 60.1224 17.7559Z"
                fill="#00FF0A"
                class="sparkle-path"
              />
            </svg>
            <div class="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div class="input-footer">
          <div class="input-wrapper">
            <input type="text" v-model="chatInput" @keyup.enter="sendChatMessage" placeholder="Enter your prompt" class="chat-input" />
            <button @click="sendChatMessage" class="send-button">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2L11 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.close-button {
  background: none;
  border: none;
  color: #8b949e;
  cursor: pointer;
  padding: 10px;
  align-self: flex-end;

  &:hover {
    color: #ffffff;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper {
  width: 90vw;
  height: 85vh;
  display: flex;
  background-color: #1a1f24;
  border: 1.677px solid var(--secondary-color);
  border-radius: 13px;
  overflow: hidden;
}

.modal-sidebar {
  width: 280px;
  background-color: var(--element-bg);
  display: flex;
  flex-direction: column;
  border-right: 1.677px solid var(--secondary-color);
  overflow: visible;
  position: relative;
  z-index: 2;

  .sidebar-header {
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    border-bottom: 1.677px solid var(--secondary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-controls {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    .properties-selector {
      width: 100%;
      margin: 0;
      position: static;

      :deep(.app-multi-selector-body) {
        position: absolute;
        margin-left: 50px;
      }
    }

    .select-control,
    .date-control {
      width: 100%;
      margin: 0;
      position: static;

      :deep(.dp--menu-wrapper) {
        position: absolute;
        margin-left: 65px;
      }
    }

    .review-button {
      padding: 8px 16px;
      background-color: #238636;
      color: white;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-size: 14px;
      width: 100%;

      &:hover {
        background-color: #2ea043;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 16px;
  }

  .chat-history {
    flex: 1;
    padding: 16px;
    overflow-y: auto;

    .chat-item {
      padding: 12px;
      color: #8b949e;
      cursor: pointer;
      border-radius: 6px;
      margin-bottom: 4px;
      font-size: 14px;

      &:hover {
        background-color: #2a333c;
      }

      &.active {
        background-color: #2a333c;
        color: #ffffff;
      }
    }
  }

  .user-profile {
    padding: 16px;
    display: flex;
    align-items: center;

    .user-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 12px;
    }

    .user-name {
      color: #8b949e;
      font-size: 14px;
    }
  }
}

.modal-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--element-bg);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  background-color: var(--element-bg);
}

.message {
  display: flex;
  margin-bottom: 24px;
  width: 100%;

  &.user {
    justify-content: flex-end;

    .message-content {
      margin-left: auto;
      margin-right: 0;
      max-width: 75%;
    }

    .message-text {
      display: inline-block;
      background-color: #238636;
      margin-left: auto;
    }
  }

  &.bot {
    .message-content {
      max-width: 75%;
    }
  }

  .bot-avatar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 12px;

    .avatar-image {
      max-height: 32px;
      border-radius: 50%;
    }
  }

  .message-content {
    display: flex;
    flex-direction: column;
  }

  .message-text {
    display: inline-block;
    padding: 12px 16px;
    background-color: #2a333c;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    word-break: break-word;
    max-width: fit-content;
  }
}

.input-footer {
  padding: 16px;
  border-top: 1.677px solid var(--secondary-color);

  .input-wrapper {
    display: flex;
    background-color: #2a333c;
    border-radius: 8px;
    padding: 8px;
  }

  .chat-input {
    flex: 1;
    background: transparent;
    border: none;
    color: #ffffff;
    padding: 8px;
    font-size: 14px;

    &::placeholder {
      color: #8b949e;
    }

    &:focus {
      outline: none;
    }
  }

  .send-button {
    padding: 8px;
    background-color: transparent;
    border: none;
    color: #238636;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #2ea043;
    }
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .dots {
    display: flex;
    gap: 4px;
    padding: 8px;
    background-color: #2a333c;
    border-radius: 8px;

    span {
      width: 8px;
      height: 8px;
      background-color: #8b949e;
      border-radius: 50%;
      animation: bounce 1.4s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
}
</style>
