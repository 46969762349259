// stores/PropertiesStore.js
import { defineStore } from 'pinia';
import axios from 'axios';
import { propertiesFilterData } from '@/data/properties';
import { filterPropertiesBySearchQuery, filterPropertiesBySelectedIds, sortProperties, sortFunctions } from '@/helpers/PropertiesHelper';
import { useCsv } from '@/composables/useCsv';

export const usePropertiesStore = defineStore('PropertiesStore', {
  state: () => ({
    // "Instant" selection states:
    selectedProperties: [],
    searchQuery: '',
    // If 'Recently' is considered a default/no-op, you might want to interpret it that way below
    selectedOrderBy: 'Recent',
    currentFilterType: 'AND',

    // "Applied" or "Committed" states (for breadcrumbs / final display):
    appliedSelectedProperties: [],
    appliedSearchQuery: '',
    appliedOrderBy: '',
    propertiesSearchPerformed: false, // toggles once user applies filters

    originalProperties: [],
    filteredPropertiesResult: [],
    isLoading: false,
    filterLoading: false,
    currentPropertyView: 'detail-grid',
    selectedOrderByOptions: [],
    propertiesOrderByData: propertiesFilterData,
  }),

  getters: {
    // isFilterApplied check:
    isFilterApplied: (state) => {
      return state.selectedProperties.length > 0 || state.searchQuery !== '' || state.selectedOrderBy !== '';
    },

    // Return final computed properties
    properties: (state) => state.filteredPropertiesResult,
  },

  actions: {
    async getProperties() {
      this.isLoading = true;
      try {
        const response = await axios.get('https://kyzen.io/v1/properties', {
          headers: { Authorization: 'Bearer 557d30a7b4147c72a424b7869f5b94c7' },
        });
        this.originalProperties = response.data.data.slice(0, 12);
        this.filteredPropertiesResult = sortProperties(this.originalProperties, this.selectedOrderBy, sortFunctions);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    updateCurrentFilterType(newFilterType) {
      this.currentFilterType = newFilterType;
    },

    resetFilters() {
      this.selectedProperties = [];
      this.searchQuery = '';
      // If you consider 'Recently' to mean "no sort filter",
      // you might set this to '' instead to truly remove the filter:
      this.selectedOrderBy = '';

      this.applyFilters();
    },

    toggleView(view) {
      this.currentPropertyView = view;
      localStorage.setItem('currentPropertyView', view);
    },

    //The standard applyFilters that finalizes the user's selections, updates the "applied" states, and triggers a fresh filter run.

    async applyFilters() {
      this.filterLoading = true;

      // Temporarily set to false so watchers/UI reactivity sees a "re-apply"
      this.propertiesSearchPerformed = false;

      // 1) Copy "instant" selections to "applied" states:
      this.appliedSelectedProperties = [...this.selectedProperties];
      this.appliedSearchQuery = this.searchQuery;
      this.appliedOrderBy = this.selectedOrderBy;

      // 2) Simulate a short delay
      await new Promise((resolve) => setTimeout(resolve, 500));

      // 3) Perform the actual filtering logic with the "applied" states
      let filteredProperties = [...this.originalProperties];

      if (this.appliedSelectedProperties.length > 0) {
        filteredProperties = filterPropertiesBySelectedIds(filteredProperties, this.appliedSelectedProperties, this.currentFilterType);
      }

      if (this.appliedSearchQuery) {
        filteredProperties = filterPropertiesBySearchQuery(filteredProperties, this.appliedSearchQuery);
      }

      filteredProperties = sortProperties(filteredProperties, this.appliedOrderBy, sortFunctions);

      this.filteredPropertiesResult = filteredProperties;

      // 4) Decide if the user truly has a "filter" or "search" applied
      // If everything is empty, remain false; otherwise, set to true
      const hasFilters =
        this.appliedSelectedProperties.length > 0 ||
        (this.appliedSearchQuery && this.appliedSearchQuery !== '') ||
        (this.appliedOrderBy && this.appliedOrderBy !== 'Recently');

      this.propertiesSearchPerformed = hasFilters;

      this.filterLoading = false;
    },
    exportPropertiesAsCsv() {
      const { convertPropertiesToCsv, downloadCsv } = useCsv();
      // "this.properties" is from the store's getter => the final filtered list
      const csvContent = convertPropertiesToCsv(this.properties);

      // Download with a custom filename
      const today = new Date().toISOString().slice(0, 10);
      downloadCsv(csvContent, `properties_export_${today}.csv`);
    },
  },
});
