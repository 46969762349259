<!-- BudgetGrid.vue -->
<script setup>
import { ref } from 'vue';
import CardLayout from './CardLayout.vue';
import { budgetCardData } from '@/data/budgets.js';

const budgets = ref(budgetCardData);
</script>

<template>
  <section class="budget-grid">
    <CardLayout v-for="budget in budgets" :key="budget.id" :budget="budget" />
  </section>
</template>

<style scoped>
.budget-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}
</style>
