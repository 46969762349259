<!-- BudgetsPageHeader.vue -->
<script setup>
import BudgetsIconDotsMenu from '@/components/budgets/page-header-components/IconDotsMenu.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';
import { useBudgetStore } from '@/stores/BudgetStore';

const budgetStore = useBudgetStore();

function toggleView(view) {
  budgetStore.toggleView(view);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <h3 class="page-title">Budgets</h3>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <AppActionButton @click="handleOptionClicked" label="New Budget">
          <AppIconAddAlt />
        </AppActionButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../styles/settings/mixins';
@import '../../styles/settings/themes';

.budgets-view-options-container {
  width: 70px;
  height: 32px;
  padding: 5px;
  gap: 22px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: var(--primary-color);
    transition: all 0.4s;
  }
}

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}
.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
