<!-- CardLayout.vue -->
<script setup>
import { defineProps, computed } from 'vue';
import { useCursorDropdown } from '@/composables/useCursorDropdown.js';

const props = defineProps({
  budget: {
    type: Object,
    required: true,
  },
});

// Dropdown composable functionality
const { showOverlay, userPosition, dropdownTarget, toggleOverlay } = useCursorDropdown();

// Formatted total values
const formattedTotal = computed(() => {
  const total = props.budget.total;
  if (typeof total === 'number') {
    return (
      'Total Budget: $' +
      total.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    );
  } else if (typeof total === 'string') {
    return total;
  }
  return total;
});

function handleAction(action) {
  console.log(`Action "${action}" chosen for budget:`, props.budget);
  showOverlay.value = false;
}
</script>

<template>
  <article class="budget-card" @contextmenu.prevent="toggleOverlay($event)">
    <div class="budget-card__header">
      <div class="budget-card__info">
        <h2 class="budget-card__title">{{ budget.name }}</h2>
        <p class="budget-card__creator">Created by {{ budget.creator }}</p>
        <p class="budget-card__edit-date">Last edited on {{ budget.lastEdited }}</p>
      </div>
      <div class="budget-card__total-pill">
        {{ formattedTotal }}
      </div>
    </div>
    <div class="budget-card__footer">
      <div class="budget-card__pills">
        <span class="budget-card__pill">{{ budget.year }}</span>
        <span class="budget-card__pill">{{ budget.type }}</span>
      </div>
    </div>

    <!-- Dropdown Menu -->
    <div
      v-if="showOverlay"
      ref="dropdownTarget"
      class="dropdown-menu"
      :style="{
        top: userPosition.y + 'px',
        left: userPosition.x + 'px',
        zIndex: userPosition.z,
      }"
    >
      <ul>
        <li @click="handleAction('edit')">Settings</li>
        <li @click="handleAction('share')">Share</li>
        <li @click="handleAction('duplicate')">Duplicate</li>
        <li @click="handleAction('delete')">Delete</li>
      </ul>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.budget-card {
  display: flex;
  max-width: 472px;
  flex-direction: column;
  font-family: Lato, sans-serif;
  border-radius: 20px;
  background-color: rgba(24, 40, 56, 1);
  width: 100%;
  border: 1px solid rgba(97, 112, 134, 1);
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    border-color: var(--primary-color);
    cursor: pointer;
  }
}

.budget-card__header {
  display: flex;
  width: 100%;
  max-width: 470px;
  padding: 26px 21px 0;
}

.budget-card__info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  flex-grow: 1;
  flex-basis: 0;
}

.budget-card__title {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 4px;
}

.budget-card__creator,
.budget-card__edit-date {
  margin: 4px 0 0;
}

.budget-card__total-pill {
  align-self: start;
  border-radius: 39px;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  padding: 8px 18px;
  border: 1px solid rgba(0, 180, 60, 1);
}

.budget-card__footer {
  border-radius: 0 0 20px 20px;
  background-color: rgba(18, 30, 42, 1);
  border-top: none;
  display: flex;
  margin-top: 45px;
  width: 100%;
  padding: 12px 20px;
}

.budget-card__pills {
  display: flex;
  align-items: center;
  gap: 11px;
}

.budget-card__pill {
  border-radius: 39px;
  background-color: rgba(97, 112, 134, 0.3);
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  padding: 8px 18px;
  display: inline-block;
  min-width: 94px;
  text-align: center;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  position: fixed;
  background: var(--dropdown-bg);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 120px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--primary-text-color);
  text-decoration: none;
  font-weight: 400;
  opacity: 0.75;
  border-radius: 7px;
  padding: 5px 30px 5px 5px;
  transition: background 0.3s ease;
}

.dropdown-menu li:hover {
  opacity: 1;
  background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
}
</style>
