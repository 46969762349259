<!-- TrendingReport.vue -->
<script setup>
import { computed } from 'vue';
import { useTrendingReportStore } from '@/stores/TrendingReportStore';

// Import all local JSON placeholders (simulating data from an API)
import websitePerformance from '@/data/trending-report/ga4WebsitePerformance.json';
import adsSearch from '@/data/trending-report/adsSearch.json';
import adsSiteRemarketing from '@/data/trending-report/adsSiteRemarketing.json';
import adsInMarketAudiences from '@/data/trending-report/adsInMarketAudiences.json';
import adsCustomIntentAudiences from '@/data/trending-report/adsCustomIntentAudiences.json';
import adsDemandGen from '@/data/trending-report/adsDemandGen.json';
import adsPerformanceMax from '@/data/trending-report/adsPerformanceMax.json';
import adsVideo from '@/data/trending-report/adsVideo.json';
import googleBusinessProfile from '@/data/trending-report/googleBusinessProfile.json';
import metaAdsPerformance from '@/data/trending-report/facebookAdsPerformance.json';
import leasingPerformance from '@/data/trending-report/leasingPerformance.json';
import lpConversionRate from '@/data/trending-report/leasingConversionRate.json';
import lpContactType from '@/data/trending-report/leasingContactType.json';
import theAvailability from '@/data/trending-report/availability.json';
import reputationOverall from '@/data/trending-report/reputationOverall.json';
import reputationGoogle from '@/data/trending-report/reputationGoogle.json';

// Import the sub components for display
import WebsitePerformance from '@/components/reports/trending-library/WebsitePerformance.vue';
import AdsSearch from '@/components/reports/trending-library/AdsSearch.vue';
import AdsSiteRemarketing from '@/components/reports/trending-library/AdsSiteRemarketing.vue';
import AdsInMarketAudiences from '@/components/reports/trending-library/AdsInMarketAudiences.vue';
import AdsCustomIntentAudiences from '@/components/reports/trending-library/AdsCustomIntentAudiences.vue';
import AdsDemandGen from '@/components/reports/trending-library/AdsDemandGen.vue';
import AdsPerformanceMax from '@/components/reports/trending-library/AdsPerformanceMax.vue';
import AdsVideo from '@/components/reports/trending-library/AdsVideo.vue';
import GoogleBusinessProfile from '@/components/reports/trending-library/GoogleBusinessProfile.vue';
import MetaAdsPerformance from '@/components/reports/trending-library/MetaAdsPerformance.vue';
import LeasingPerformance from '@/components/reports/trending-library/LeasingPerformance.vue';
import LpConversionRate from '@/components/reports/trending-library/LpConversionRate.vue';
import LpContactType from '@/components/reports/trending-library/LpContactType.vue';
import TheAvailability from '@/components/reports/trending-library/TheAvailability.vue';
import ReputationOverall from '@/components/reports/trending-library/ReputationOverall.vue';
import ReputationGoogle from '@/components/reports/trending-library/ReputationGoogle.vue';

// access the store
const trendingReportStore = useTrendingReportStore();

// dateRanges from the store (used in table column headers)
const dateRanges = computed(() => trendingReportStore.dateRanges);

// combine all local JSON placeholders into one "master" object.
const trendingData = computed(() => {
  return {
    website_performance: websitePerformance.metrics,
    ads_search: adsSearch.metrics,
    ads_site_remarketing: adsSiteRemarketing.metrics,
    ads_in_market_audiences: adsInMarketAudiences.metrics,
    ads_custom_intent_audiences: adsCustomIntentAudiences.metrics,
    ads_demand_gen: adsDemandGen.metrics,
    ads_p_max: adsPerformanceMax.metrics,
    ads_video: adsVideo.metrics,
    google_business_profile: googleBusinessProfile.metrics,
    meta_ads_performance: metaAdsPerformance.metrics,
    leasing_performance: leasingPerformance.metrics,
    lp_conversion_rate: lpConversionRate.metrics,
    lp_contact_type: lpContactType.metrics,
    availability: theAvailability.metrics,
    reputation_overall: reputationOverall.metrics,
    reputation_google: reputationGoogle.metrics,
  };
});

// function to format date ranges for display in the table
function reportDate(end, start) {
  if (start === end) {
    return start;
  }
  return `${start} - ${end}`;
}
</script>

<template>
  <div class="table-container">
    <table class="custom-table table-striped table-hover table-responsive">
      <tbody>
        <!-- GA4 Website Performance -->
        <template v-if="trendingReportStore.report.website_performance">
          <tr>
            <th>GA4: Website Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <WebsitePerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads Search -->
        <template v-if="trendingReportStore.report.ads_search">
          <tr>
            <th>Google Ads: Search</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsSearch :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads Site Remarketing -->
        <template v-if="trendingReportStore.report.ads_site_remarketing">
          <tr>
            <th>Google Ads: Site Remarketing</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsSiteRemarketing :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads In-Market Audiences -->
        <template v-if="trendingReportStore.report.ads_in_market_audiences">
          <tr>
            <th>Google Ads: In-Market Audiences</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsInMarketAudiences :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads Custom Intent Audiences -->
        <template v-if="trendingReportStore.report.ads_custom_intent_audiences">
          <tr>
            <th>Google Ads: Custom Intent Audiences</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsCustomIntentAudiences :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads Demand Gen -->
        <template v-if="trendingReportStore.report.ads_demand_gen">
          <tr>
            <th>Google Ads: Demand Gen</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsDemandGen :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads P-Max -->
        <template v-if="trendingReportStore.report.ads_p_max">
          <tr>
            <th>Google Ads: Performance Max</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsPerformanceMax :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Ads Video -->
        <template v-if="trendingReportStore.report.ads_video">
          <tr>
            <th>Google Ads: Video</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <AdsVideo :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Google Business Profile -->
        <template v-if="trendingReportStore.report.google_business_profile">
          <tr>
            <th>Google Business Profile</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <GoogleBusinessProfile :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Meta Ads Performance -->
        <template v-if="trendingReportStore.report.meta_ads_performance">
          <tr>
            <th>Meta Ads Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <MetaAdsPerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Leasing Performance -->
        <template v-if="trendingReportStore.report.leasing_performance">
          <tr>
            <th>Leasing Performance</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <LeasingPerformance :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- LP Conversion Rate -->
        <template v-if="trendingReportStore.report.lp_conversion_rate">
          <tr>
            <th>Leasing Performance: Conversion Rate</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <LpConversionRate :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- LP Contact Type -->
        <template v-if="trendingReportStore.report.lp_contact_type">
          <tr>
            <th>Leasing Performance: Contact Type</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <LpContactType :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Availability -->
        <template v-if="trendingReportStore.report.availability">
          <tr>
            <th>Availability</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <TheAvailability :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Reputation: Overall -->
        <template v-if="trendingReportStore.report.reputation_overall">
          <tr>
            <th>Reputation: Overall</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <ReputationOverall :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>

        <!-- Reputation: Google -->
        <template v-if="trendingReportStore.report.reputation_google">
          <tr>
            <th>Reputation: Google</th>
            <th v-for="(range, index) in dateRanges" :key="index">
              {{ reportDate(range.end, range.start) }}
            </th>
            <th>Total</th>
          </tr>
          <ReputationGoogle :numOfPeriods="trendingReportStore.numSelectedPeriod" />
        </template>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table-container {
  border: 2px var(--secondary-color) solid;
  border-radius: 7px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease;
    scrollbar-gutter: auto;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.btn-export-csv {
  background: var(--primary-color);
  margin: 15px;
  border: none;
  border-radius: 21px;
  padding: 10px 30px;
  font-size: 14px;
  float: right;
}

.custom-table {
  width: 100%;
  min-width: 100%;
  border-spacing: 0;
  text-align: left;
  border-collapse: separate;

  tbody {
    tr {
      background-color: var(--property-card-bg);
      font-weight: bold;

      th {
        padding: 12px;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--secondary-color);
        border-right: 1px var(--secondary-color) solid;
        border-left: 1px var(--secondary-color) solid;
        border-bottom: 1px var(--secondary-color) solid;
        border-top: 1px var(--secondary-color) solid;
      }

      th:first-child {
        border-left: none;
      }

      th:last-child {
        border-right: none;
      }
    }

    tr:first-child th {
      border-top: none;
    }

    tr:last-child th {
      border-bottom: none;
    }
  }
}
</style>
