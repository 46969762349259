<script setup>
import { computed } from 'vue';
import { useModalStore } from '@/stores/ModalStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import AppSpinner from '@/components/ui/AppSpinner.vue';

// Modal imports
import SettingsGa4Modal from '@/components/ui/modals/SettingsGa4Modal.vue';
import SettingsGoogleAdsModal from '@/components/ui/modals/SettingsGoogleAdsModal.vue';
import SettingsGBPModal from '@/components/ui/modals/SettingsGBPModal.vue';
import SettingsGTMModal from '@/components/ui/modals/SettingsGTMModal.vue';
import SettingsKyzenApiModal from '@/components/ui/modals/SettingsKyzenApiModal.vue';
import SettingsChatMeterModal from '@/components/ui/modals/SettingsChatMeterModal.vue';
import SettingsNotSyncedModal from '@/components/ui/modals/SettingsNotSyncedModal.vue';

// View components imports
import ProfileGridView from '@/components/settings/ProfileGridView.vue';
import GroupsGridView from '@/components/settings/GroupsGridView.vue';
import PermissionsGridView from '@/components/settings/PermissionsGridView.vue';
import IntegrationsGridView from '@/components/settings/IntegrationsGridView.vue';

// Store instances
const modalStore = useModalStore();
const settingsStore = useSettingsStore();

// Map the settings to components
const componentMap = {
  Profile: ProfileGridView,
  Groups: GroupsGridView,
  Permissions: PermissionsGridView,
  Integrations: IntegrationsGridView,
};

// Computed property to determine the current view component
const currentViewComponent = computed(() => {
  return componentMap[settingsStore.currentView] || IntegrationsGridView;
});

// Fetch settings data when the component is mounted
settingsStore.fetchSettings();
</script>

<template>
  <div class="styles_view__body">
    <!-- Show initial loading spinner while fetching settings data -->
    <div v-if="settingsStore.isLoading" class="center-spinner">
      <AppSpinner />
    </div>

    <!-- Main Content: Display after initial loading is complete -->
    <div v-else>
      <!-- Render the current view component directly -->
      <component :is="currentViewComponent" />
    </div>

    <!-- Modals -->
    <SettingsGa4Modal v-if="modalStore.isModalOpen('syncedGoogleAnalytics4Modal')" />
    <SettingsGoogleAdsModal v-if="modalStore.isModalOpen('syncedGoogleAdsModal')" />
    <SettingsGBPModal v-if="modalStore.isModalOpen('syncedGoogleBusinessProfileModal')" />
    <SettingsGTMModal v-if="modalStore.isModalOpen('syncedGoogleTagManagerModal')" />
    <SettingsKyzenApiModal v-if="modalStore.isModalOpen('syncedKyzenAPIModal')" />
    <SettingsChatMeterModal v-if="modalStore.isModalOpen('syncedChatmeterAPIModal')" />
    <SettingsNotSyncedModal v-if="modalStore.isModalOpen('settingsNotSyncedModal')" />
    <!-- Modals End -->
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';

.styles_view__body {
  overflow: visible;
}

.center-spinner {
  display: flex;
  justify-content: center;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}
</style>
