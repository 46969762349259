// BenchmarkReportStore.js
import { defineStore } from 'pinia';
import { useCsv } from '@/composables/useCsv';

export const useBenchmarkReport = defineStore('BenchmarkReportStore', {
  state: () => ({
    benchmarkReport: {
      report_type: 'benchmark_report',
      period_type: 3, // 1 = daily, 2 = weekly, 3 = monthly
    },
    reportName: 'Benchmark Report',
    recurringOptions: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    selectedRecurring: 'no',
    periodOptions: [
      { label: 'Daily', value: 1 },
      { label: 'Weekly', value: 2 },
      { label: '30 day', value: 3 },
      { label: 'Monthly', value: 4 },
      { label: 'Yearly', value: 5 },
    ],
    selectedPeriod: 3,
    numPeriodOptions: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 },
    ],
    numSelectedPeriod: 6,
    benchmarkReportOptions: [
      { label: 'Website Performance', value: 'ga4_website_performance' },
      { label: 'Ads Search', value: 'ads_search' },
      { label: 'Ads Site Remarketing', value: 'ads_site_remarketing' },
      { label: 'Ads In-Market Audiences', value: 'ads_in_market_audiences' },
      { label: 'Ads Custom Intent Audiences', value: 'ads_custom_intent_audiences' },
      { label: 'Ads Demand Gen', value: 'ads_demand_gen' },
      { label: 'Ads P-Max', value: 'ads_p_max' },
      { label: 'Ads Video', value: 'ads_video' },
      { label: 'Google Business Profile', value: 'google_business_profile' },
      { label: 'Meta Ads Performance', value: 'meta_ads_performance' },
      { label: 'Leasing Performance', value: 'leasing_performance' },
      { label: 'LP Conversion Rate', value: 'lp_conversion_rate' },
      { label: 'LP Contact Type', value: 'lp_contact_type' },
      { label: 'Availability', value: 'availability' },
      { label: 'Reputation Overall', value: 'reputation_overall' },
      { label: 'Reputation Google', value: 'reputation_google' },
    ],
    selectedReportOptions: ['ga4_website_performance', 'ads_search'],
    groupOptions: [
      { label: 'All', value: 'all' },
      { label: 'Group 1', value: 'group_1' },
      { label: 'Group 2', value: 'group_2' },
    ],
    selectedGroup: 'all',
    categoryOptions: [
      { label: 'All', value: 'all' },
      { label: 'Category 1', value: 'category_1' },
      { label: 'Category 2', value: 'category_2' },
    ],
    selectedCategoryOptions: [],
  }),

  getters: {
    // Determines which sections are enabled for the benchmark report
    report(state) {
      const report = {};
      state.benchmarkReportOptions.forEach((option) => {
        report[option.value] = state.selectedReportOptions.includes(option.value);
      });
      return report;
    },
  },

  actions: {
    toggleOption(option) {
      const index = this.selectedReportOptions.indexOf(option.value);
      if (index > -1) {
        this.selectedReportOptions.splice(index, 1);
      } else {
        this.selectedReportOptions.push(option.value);
      }
    },

    updateReportSettings(newSettings) {
      this.reportName = newSettings.reportName;
      this.selectedRecurring = newSettings.selectedRecurring;
      this.selectedPeriod = newSettings.selectedPeriod;
      this.numSelectedPeriod = newSettings.numSelectedPeriod;
      this.selectedReportOptions = [...newSettings.selectedReportOptions];
      this.benchmarkReport.period_type = parseInt(newSettings.selectedPeriod);
    },

    exportBenchmarkReportAsCsv(reportData) {
      const { convertBenchmarkReportToCsv, downloadCsv } = useCsv();

      // generate the csv string using the store’s report getter to see which columns/sections are actually enabled
      const csvString = convertBenchmarkReportToCsv(reportData, this.report);

      // download file trigger
      const today = new Date().toISOString().slice(0, 10);
      const filename = `benchmark_report_${today}.csv`;
      downloadCsv(csvString, filename);
    },
  },
});
