<!-- dashboard/landingpage.vue -->
<script setup>
import { communityDashboards } from '@/data/dashboardData';
import CardLayout from './CardLayout.vue';
import { useDashboardsStore } from '@/stores/DashboardsStore';
import { computed, onMounted } from 'vue';
//Logic
const dashboardStore = useDashboardsStore();
onMounted(() => {
  dashboardStore.loadDashboardOptions();
});
const dashboardOptions = computed(() => {
  return dashboardStore.dashboardOptions.length > 0
    ? dashboardStore.dashboardOptions.slice().sort((a, b) => a.label.localeCompare(b.label))
    : defaultOptions;
});

const defaultOptions = [{ value: null, label: 'No dashboards available' }];
</script>

<template>
  <div class="dashboard-container">
    <!-- Dashboard Grid -->
    <div class="grid-container">
      <CardLayout
        v-for="(dashboard, index) in dashboardOptions"
        :key="index"
        :title="dashboard.label"
        :lastEdited="'Last edited on Jan 24 2025'"
        :buttonText="'View'"
        :buttonType="'view'"
        :dashboardId="dashboard.id"
      />
    </div>

    <div class="section-header">Trending Dashboards</div>
    <div class="grid-container">
      <CardLayout
        v-for="(dashboard, index) in communityDashboards"
        :key="index"
        :title="dashboard.title"
        :lastEdited="dashboard.creator"
        :buttonText="dashboard.buttonText"
        :buttonType="dashboard.buttonType"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.dashboard-container {
  padding: 20px;
  color: #e0e0e0;
}

.section-header {
  color: var(--muted-text);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
  margin-bottom: 40px;

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.featured-card {
  width: 100%;
  height: 405px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  background-color: var(--property-info-bg);
  border: var(--border-size) solid var(--secondary-color);

  h2 {
    color: var(--primary-text-color);
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
