<!-- AlertsViewPageHeaderSection.vue -->
<script setup>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useModalStore } from '@/stores/ModalStore';
import { useAlertsStore } from '@/stores/AlertsStore';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';

// Store references
const modalStore = useModalStore();
const alertsStore = useAlertsStore();

// Overlay logic (if needed)
const isOverlayVisible = ref(false);
const target = ref(null);

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

// Open "Add Alert" modal
function handleOpenAddAlertsModal() {
  modalStore.openModal('AddAlertsModal');
}

// Root breadcrumb action: resets everything
function goToAlertsRoot() {
  alertsStore.resetSearch();
}

// Build the breadcrumb items from the store’s "applied" states
const breadcrumbItems = computed(() => {
  const items = [
    {
      text: 'Reset',
      action: goToAlertsRoot,
    },
  ];

  // If a specific "appliedType" is set
  if (alertsStore.appliedType) {
    items.push({ text: alertsStore.appliedType });
  }

  // If there's a search keyword
  if (alertsStore.appliedKeyword) {
    items.push({ text: alertsStore.appliedKeyword });
  }

  // If there's an applied sort
  if (alertsStore.appliedOrderBy) {
    items.push({ text: `${alertsStore.appliedOrderBy}` });
  }

  return items;
});
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side of page header -->
      <div>
        <transition name="fade-breadcrumbs">
          <AppBreadcrumbs v-if="alertsStore.searchPerformed" :items="breadcrumbItems" class="breadcrumbs" />
        </transition>
        <h3>Alerts</h3>
      </div>

      <!-- Right side of page header -->
      <div class="styles_pageHeader__icon-container">
        <div>
          <AppActionButton @click="handleOpenAddAlertsModal" label="New Alert">
            <AppIconAddAlt />
          </AppActionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}
.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
