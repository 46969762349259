<!-- UsersPageHeader.vue -->
<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useUsersStore } from '@/stores/UsersStore';

// Components
import AppIconDotsVertical from '@/components/svg/AppIconDotsVertical.vue';
import AppActionButton from '@/components/ui/buttons/AppActionButton.vue';
import AppIconAddAlt from '@/components/svg/AppIconAddAlt.vue';
import AppIconBoxWrapper from '@/components/wrappers/AppIconBoxWrapper.vue';
import AppOverlayMenu from '@/components/ui/AppOverlayMenu.vue';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs.vue';

const usersStore = useUsersStore();

// Overlay logic (dots menu)
const isOverlayVisible = ref(false);
const target = ref(null);

function toggleOverlay() {
  isOverlayVisible.value = !isOverlayVisible.value;
}

onClickOutside(target, () => {
  if (isOverlayVisible.value) {
    isOverlayVisible.value = false;
  }
});

function handleOptionClicked(option) {
  console.log(`Option ${option.text} clicked`);
}
</script>

<template>
  <div class="styles_pageHeader__section">
    <div class="styles_pageHeader__container">
      <!-- Left side: Title + Breadcrumbs -->
      <div>
        <!-- Show breadcrumbs only after user has applied filters (searchPerformed = true) -->
        <transition name="fade-breadcrumbs">
          <!-- Show breadcrumbs only after user has applied filters (searchPerformed = true) -->
          <AppBreadcrumbs v-if="usersStore.searchPerformed" :items="breadcrumbItems" class="breadcrumbs" />
        </transition>
        <h3 class="page-title">Users</h3>
      </div>

      <!-- Right side: Dots menu -->
      <div class="styles_pageHeader__icon-container">
        <AppActionButton @click="handleOptionClicked" label="Add User">
          <AppIconAddAlt />
        </AppActionButton>

        <div ref="target">
          <AppIconBoxWrapper @click="toggleOverlay">
            <AppIconDotsVertical />
          </AppIconBoxWrapper>
          <AppOverlayMenu v-if="isOverlayVisible" @option-clicked="handleOptionClicked" :options="['export', 'import', 'settings']">
            <template #export>
              <div>Export</div>
            </template>
            <template #import>
              <div>Import</div>
            </template>
            <template #settings>
              <div>Settings</div>
            </template>
          </AppOverlayMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';
@import 'src/styles/settings/_themes.scss';

.styles_pageHeader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_pageHeader__icon-container {
  display: flex;
  gap: 10px;
}

.fade-breadcrumbs-enter-active,
.fade-breadcrumbs-leave-active {
  transition: opacity 0.3s ease;
}

.fade-breadcrumbs-enter-from,
.fade-breadcrumbs-leave-to {
  opacity: 0;
}
</style>
