<!-- AppListPills.vue -->
<script setup>
import { ref, computed, watch } from 'vue';
import AppSearch from '@/components/ui/search/AppSearch.vue';
import { onClickOutside } from '@vueuse/core';

// Props
const props = defineProps({
  title: { type: String, required: false },
  listItems: { type: Array, required: true }, // List of items to display
  useRawItem: { type: Boolean, default: false },
  modelValue: { type: Array, default: () => [] },
  placeholder: { type: String, default: 'Click to add' },
  disabled: { type: Boolean, default: false },
});

// Emit for the v-model
const emit = defineEmits(['update:modelValue']);

// State
const selectedItems = ref([...props.modelValue]);
const searchQuery = ref('');
const dropdownOpen = ref(false);
const dropdownPosition = ref({ top: 0, left: 0 });

// Refs
const target = ref(null);

// Watchers
watch(
  () => props.modelValue,
  (newVal) => {
    selectedItems.value = [...newVal];
  }
);

const filteredItems = computed(() => {
  return props.listItems.filter((item) => {
    if (!item) return false;
    return props.useRawItem
      ? item.toLowerCase().includes(searchQuery.value.toLowerCase())
      : item.name?.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});

// Toggle item selection
function toggleSelection(item) {
  if (props.disabled) return;

  const isSelected = props.useRawItem ? selectedItems.value.includes(item) : selectedItems.value.some((selected) => selected.id === item.id);

  if (isSelected) {
    selectedItems.value = props.useRawItem
      ? selectedItems.value.filter((selected) => selected !== item)
      : selectedItems.value.filter((selected) => selected.id !== item.id);
  } else {
    selectedItems.value.push(item);
  }

  emit('update:modelValue', selectedItems.value);
}

// Remove item
function removeItem(item) {
  selectedItems.value = props.useRawItem
    ? selectedItems.value.filter((selected) => selected !== item)
    : selectedItems.value.filter((selected) => selected.id !== item.id);

  emit('update:modelValue', selectedItems.value);
}

/**
 * Helper function to compute the (x,y) coordinates for the center
 * of the modal. Will probably extract this to its own file.
 *  This can be:
 *   1) A direct ref to a modal element, or
 *   2) A querySelector that finds the modal container.
 */
function getModalCenterPosition(modalEl) {
  if (!modalEl) return { top: 0, left: 0 };
  const rect = modalEl.getBoundingClientRect();
  return {
    top: rect.top + rect.height / 2 + window.scrollY,
    left: rect.left + rect.width / 2 + window.scrollX,
  };
}

/**
 * Toggle dropdown: we set `dropdownPosition` so that the dropdown
 * appears centered in the modal.  Here we assume that the modal
 * has a ref called `modalRef`, or some querySelector.  Adjust to match
 * unique modal code.
 */
function toggleDropdown() {
  if (props.disabled) return;
  dropdownOpen.value = !dropdownOpen.value;

  if (dropdownOpen.value) {
    // For example, if the modal has a ref in the parent component:
    // const modalEl = theModalRef.value;
    // Or, if we can target it by a DOM selector:
    // const modalEl = document.querySelector('.the-modal-class');

    const modalEl = document.querySelector('.your-modal-class'); // or use a ref
    dropdownPosition.value = getModalCenterPosition(modalEl);
  }
}

// Auto-open if user starts typing
watch(searchQuery, (newVal) => {
  if (newVal.length > 0) {
    dropdownOpen.value = true;
    const modalEl = document.querySelector('.your-modal-class');
    dropdownPosition.value = getModalCenterPosition(modalEl);
  }
});

// Close if clicked outside
onClickOutside(target, () => {
  if (dropdownOpen.value) dropdownOpen.value = false;
});
</script>

<template>
  <div class="listbox-pill-dropdown-container" ref="target">
    <label v-if="title" class="input-label">{{ title }}</label>

    <!-- Selected Items -->
    <div class="selected-items" @click="toggleDropdown" :class="{ disabled: disabled }">
      <div v-for="item in selectedItems" :key="useRawItem ? item : item.id" class="pill">
        {{ useRawItem ? item : item.name }}
        <button @click.stop="removeItem(item)" class="remove-button">x</button>
      </div>
      <div v-if="selectedItems.length === 0" class="placeholder">
        {{ placeholder }}
      </div>
    </div>

    <!-- Dropdown Items -->
    <div
      v-if="dropdownOpen"
      class="dropdown-wrapper"
      :style="{
        top: dropdownPosition.top + 'px',
        left: dropdownPosition.left + 'px',
        transform: 'translate(30%, 30%)',
      }"
    >
      <div class="dropdown-body">
        <AppSearch v-model="searchQuery" />
        <ul class="dropdown-list">
          <li v-for="item in filteredItems" :key="useRawItem ? item : item.id" class="dropdown-item" @click="toggleSelection(item)">
            <label class="checkbox-container">
              <input
                type="checkbox"
                :checked="useRawItem ? selectedItems.includes(item) : selectedItems.some((s) => s.id === item.id)"
                @change="toggleSelection(item)"
              />
              <span class="custom-checkbox"></span>
              {{ useRawItem ? item : item.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/styles/settings/_mixins.scss';

.input-label {
  display: block;
  font-weight: 400;
  padding-left: 12px;
  font-size: 13px;
  line-height: 1;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}

.listbox-pill-dropdown-container {
  padding: 10px 0;
  position: relative;

  .selected-items.disabled {
    cursor: not-allowed;
    background: rgba(232, 231, 231, 0.08);

    + .dropdown-wrapper {
      display: none;
    }

    .pill {
      &:hover {
        .remove-button {
          visibility: hidden;
        }
      }
    }
  }
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
  align-content: flex-start;
  height: auto;
  min-height: 250px;
  margin-bottom: 10px;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 12px;
  background: none;
  padding: 12px;
  color: var(--primary-text-color);
  transition: 0.4s;
  cursor: pointer;
  overflow-y: auto;

  .pill {
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    color: #fff;
    padding: 5px 8px 5px 20px;
    border-radius: 15px;
    font-size: 14px;
    height: 30px;
    transition: all 0.4s ease;

    &:hover {
      .remove-button {
        visibility: visible;
      }
    }

    .remove-button {
      background: none;
      border: none;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-left: 8px;
      position: relative;
      bottom: 1px;
      visibility: hidden;
    }
  }

  .placeholder {
    color: var(--secondary-color);
  }
}

.dropdown-wrapper {
  position: fixed;
  z-index: 9999;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  margin-top: 5px;
  padding: 15px;
  min-width: 300px;

  .dropdown-body {
    position: relative;
  }

  .dropdown-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 15px;

    .dropdown-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 4px;
      font-size: 16px;
      border-radius: 5px;
      background: none;

      &:hover {
        background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
      }
    }

    &::-webkit-scrollbar {
      width: 9px;
      transition:
        width 0.5s ease,
        background-color 0.5s ease;
      scrollbar-gutter: auto;
    }

    &::-webkit-scrollbar-track {
      background: var(--property-card-bg);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(var(--secondary-color-rgb), 0.4);
      transition: background 0.3s ease;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(var(--secondary-color-rgb), 1);
    }
  }

  .no-results {
    margin-top: 10px;
    color: var(--secondary-color);
    font-size: 14px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .custom-checkbox {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: none;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    margin-right: 10px;
    transition:
      background-color 0.3s,
      border-color 0.3s;
    position: relative;
  }

  input[type='checkbox']:checked + .custom-checkbox {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
  }

  input[type='checkbox']:checked + .custom-checkbox::after {
    display: block;
  }

  .custom-checkbox::after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>
