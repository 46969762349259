<script setup>
import { ref } from 'vue';
import { useReportsStore } from '@/stores/ReportsStore';
import { useCursorDropdown } from '@/composables/useCursorDropdown';

const reportsStore = useReportsStore();

// temporary reports data
const reports = ref([
  {
    id: 1,
    label: 'Benchmark',
    date: 'May 14 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 2,
    label: 'ETA',
    date: 'Aug 11 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 3,
    label: 'Extension',
    date: 'Nov 14 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 4,
    label: 'Keyword',
    date: 'Jun 9 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 5,
    label: 'RSA',
    date: 'Jul 4 2024',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora.',
  },
  {
    id: 6,
    label: 'Trending',
    date: 'Jul 19 2024',
    description:
      'The trending report can be created for a specific community, group of properties, or your entire portfolio. It is a great way to analyze performance on a high level, to see how results have improved or declined over several consistent time periods.',
  },
]);

// cursor-positioned dropdown
const { showOverlay, userPosition, dropdownTarget, toggleOverlay } = useCursorDropdown();
const selectedReportId = ref(null); // track selected report

// open dropdown and set selected report ID
function openDropdown(event, reportId) {
  selectedReportId.value = reportId;
  toggleOverlay(event);
}

// handle option selection
function handleOptionSelect(option) {
  if (option === 'View') {
    reportsStore.loadReport(selectedReportId.value);
  } else if (option === 'Customize') {
    // adding modal here in separate task
  }

  // close dropdown after selection
  showOverlay.value = false;
}
</script>

<template>
  <div class="report-grid">
    <div v-for="report in reports" :key="report.id" class="report-block" @click="openDropdown($event, report.id)">
      <div class="report-content">
        <div class="report-header">
          <div class="report-avatar"></div>
          <div class="report-info">
            <h3>{{ report.label }} Report</h3>
            <p class="last-edited">
              Last edited on <span>{{ report.date }}</span>
            </p>
          </div>
        </div>
        <p class="description">
          {{ report.description }}
        </p>
      </div>
    </div>
  </div>

  <div
    v-if="showOverlay"
    ref="dropdownTarget"
    class="dropdown-menu"
    :style="{
      position: 'fixed',
      top: `${userPosition.y}px`,
      left: `${userPosition.x}px`,
      zIndex: userPosition.z,
    }"
  >
    <ul>
      <li @click="handleOptionSelect('View')" class="link-text">View</li>
      <li @click="handleOptionSelect('Customize')" class="link-text">Customize</li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/_mixins.scss';

.report-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.report-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--property-card-bg);
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease;
  overflow: auto;
  cursor: pointer;
  height: 225px;

  /* Custom scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 9px;
    transition:
      width 0.5s ease,
      background-color 0.5s ease;
    /* Increase transition duration */
    scrollbar-gutter: auto;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: var(--property-card-bg);
    margin: 12px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(var(--secondary-color-rgb), 0.4);
    transition: background 0.3s ease;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--secondary-color-rgb), 1);
  }
}

.report-content {
  padding: 25px;
  padding-bottom: 0;
}

.report-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.report-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--property-info-bg);
}

.report-info h3 {
  font-family: Lato;
  font-size: 20.083px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.last-edited {
  margin: 3px 0 0;
  color: var(--secondary-color);
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 300;
}

.description {
  font-family: Lato;
  font-size: 14.606px;
  font-style: normal;
  font-weight: 300;
  margin: 15px 0 20px;
}

.dropdown-menu {
  background: var(--dropdown-bg);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  min-width: 120px;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--primary-text-color);
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    opacity: 0.75;
    border-radius: 7px;
    padding: 5px 30px 5px 5px;

    &:hover {
      opacity: 100;
      transition: all 0.3s ease;
      background: var(--property-metric-bg);
      background: linear-gradient(90deg, var(--property-metric-bg) 0%, var(--dropdown-bg) 100%);
    }
  }
}

// Responsive adjustments
@media (max-width: 1439px) {
  .report-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .report-grid {
    grid-template-columns: 1fr;
  }
}
</style>
