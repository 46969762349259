// useCsv.js

// Converting a list of properties into CSV for the Properties page.
function convertPropertiesToCsv(properties) {
  const headers = ['Name', 'Location', 'Integration', 'Occupancy Rate', 'Exposure Rate'];

  const rows = properties.map((property) => {
    const name = property.attributes?.name || '';
    const location = property.attributes?.address || '';
    const integration = 'Integration';
    const occupancy = property.attributes?.occupancy_rate || 0;
    const exposure = property.attributes?.exposure_rate || 0;

    return [name, location, integration, occupancy, exposure];
  });

  const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');

  return csvContent;
}

// Trigger CSV download in the user's browser.
function downloadCsv(csvString, filename = 'properties_export.csv') {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
  link.remove();
}

// Convert dynamic Benchmark data into CSV text.
function convertBenchmarkReportToCsv(reportData, report) {
  const sections = [];
  if (report.ga4_website_performance) {
    sections.push({ sectionKey: 'ga4_website_performance', headerLabel: 'Ga4 Website Performance' });
  }
  if (report.ads_search) {
    sections.push({ sectionKey: 'ads_search', headerLabel: 'Google Ads: Search' });
  }
  if (report.ads_site_remarketing) {
    sections.push({ sectionKey: 'ads_site_remarketing', headerLabel: 'Google Ads: Site Remarketing' });
  }
  if (report.ads_in_market_audiences) {
    sections.push({ sectionKey: 'ads_in_market_audiences', headerLabel: 'Google Ads: In-Market Audiences' });
  }
  if (report.ads_custom_intent_audiences) {
    sections.push({ sectionKey: 'ads_custom_intent_audiences', headerLabel: 'Google Ads: Custom Intent Audiences' });
  }
  if (report.ads_demand_gen) {
    sections.push({ sectionKey: 'ads_demand_gen', headerLabel: 'Google Ads: Demand Gen' });
  }
  if (report.ads_p_max) {
    sections.push({ sectionKey: 'ads_p_max', headerLabel: 'Google Ads: P-Max' });
  }
  if (report.ads_video) {
    sections.push({ sectionKey: 'ads_video', headerLabel: 'Google Ads: Video' });
  }
  if (report.google_business_profile) {
    sections.push({ sectionKey: 'google_business_profile', headerLabel: 'Google Business Profile' });
  }
  if (report.meta_ads_performance) {
    sections.push({ sectionKey: 'meta_ads_performance', headerLabel: 'Meta Ads Performance' });
  }
  if (report.leasing_performance) {
    sections.push({ sectionKey: 'leasing_performance', headerLabel: 'Leasing Performance' });
  }
  if (report.lp_conversion_rate) {
    sections.push({ sectionKey: 'lp_conversion_rate', headerLabel: 'LP Conversion Rate' });
  }
  if (report.lp_contact_type) {
    sections.push({ sectionKey: 'lp_contact_type', headerLabel: 'LP Contact Type' });
  }
  if (report.availability) {
    sections.push({ sectionKey: 'availability', headerLabel: 'Availability' });
  }
  if (report.reputation_overall) {
    sections.push({ sectionKey: 'reputation_overall', headerLabel: 'Reputation: Overall' });
  }
  if (report.reputation_google) {
    sections.push({ sectionKey: 'reputation_google', headerLabel: 'Reputation: Google' });
  }

  const headerRow = ['Property'];
  if (reportData.length > 0) {
    sections.forEach((section) => {
      const metricsArray = reportData[0][section.sectionKey] || [];
      metricsArray.forEach((metric) => {
        headerRow.push(`${section.headerLabel}: ${metric.name}`);
      });
    });
  }

  const rows = reportData.map((item) => {
    const row = [item.property];
    sections.forEach((section) => {
      const metricsArray = item[section.sectionKey] || [];
      metricsArray.forEach((metric) => {
        row.push(metric.values?.[0] ?? '');
      });
    });
    return row;
  });

  const csvContent = [headerRow, ...rows].map((cols) => cols.join(',')).join('\n');

  return csvContent;
}

function convertTrendingReportToCsv(trendingData, report, dateRanges = []) {
  // which sections are enabled and in which order?
  const sections = [];
  if (report.website_performance) {
    sections.push({ sectionKey: 'website_performance', headerLabel: 'GA4: Website Performance' });
  }
  if (report.ads_search) {
    sections.push({ sectionKey: 'ads_search', headerLabel: 'Google Ads: Search' });
  }
  if (report.ads_site_remarketing) {
    sections.push({ sectionKey: 'ads_site_remarketing', headerLabel: 'Google Ads: Site Remarketing' });
  }
  if (report.ads_in_market_audiences) {
    sections.push({ sectionKey: 'ads_in_market_audiences', headerLabel: 'Google Ads: In-Market Audiences' });
  }
  if (report.ads_custom_intent_audiences) {
    sections.push({ sectionKey: 'ads_custom_intent_audiences', headerLabel: 'Google Ads: Custom Intent Audiences' });
  }
  if (report.ads_demand_gen) {
    sections.push({ sectionKey: 'ads_demand_gen', headerLabel: 'Google Ads: Demand Gen' });
  }
  if (report.ads_p_max) {
    sections.push({ sectionKey: 'ads_p_max', headerLabel: 'Google Ads: Performance Max' });
  }
  if (report.ads_video) {
    sections.push({ sectionKey: 'ads_video', headerLabel: 'Google Ads: Video' });
  }
  if (report.google_business_profile) {
    sections.push({ sectionKey: 'google_business_profile', headerLabel: 'Google Business Profile' });
  }
  if (report.meta_ads_performance) {
    sections.push({ sectionKey: 'meta_ads_performance', headerLabel: 'Meta Ads Performance' });
  }
  if (report.leasing_performance) {
    sections.push({ sectionKey: 'leasing_performance', headerLabel: 'Leasing Performance' });
  }
  if (report.lp_conversion_rate) {
    sections.push({ sectionKey: 'lp_conversion_rate', headerLabel: 'Leasing Performance: Conversion Rate' });
  }
  if (report.lp_contact_type) {
    sections.push({ sectionKey: 'lp_contact_type', headerLabel: 'Leasing Performance: Contact Type' });
  }
  if (report.availability) {
    sections.push({ sectionKey: 'availability', headerLabel: 'Availability' });
  }
  if (report.reputation_overall) {
    sections.push({ sectionKey: 'reputation_overall', headerLabel: 'Reputation: Overall' });
  }
  if (report.reputation_google) {
    sections.push({ sectionKey: 'reputation_google', headerLabel: 'Reputation: Google' });
  }

  // Add a single header row: For each section => columns for each dateRange + a "Total"
  const headerRow = [];
  sections.forEach((section) => {
    dateRanges.forEach((range) => {
      headerRow.push(`${section.headerLabel}: ${range.start} - ${range.end}`);
    });
    headerRow.push(`${section.headerLabel}: TOTAL`);
  });

  // Build data rows by gathering all unique metric names across all sections
  // Then each row = 1 metric's data for each section + each dateRange
  const allMetricNames = new Set();

  // trendingData is presumably an object keyed by section:
  // e.g. trendingData.website_performance => [ { name: 'Sessions', ...}, ... ]
  sections.forEach(({ sectionKey }) => {
    const metricsArray = trendingData[sectionKey] || [];
    metricsArray.forEach((m) => {
      allMetricNames.add(m.name);
    });
  });

  const rows = [];

  // For each metric name, build a single row
  allMetricNames.forEach((metricName) => {
    // First column: the metric name
    const row = [metricName];

    sections.forEach(({ sectionKey }) => {
      const metricsArray = trendingData[sectionKey] || [];
      // Find the metric object in this array
      const foundMetric = metricsArray.find((m) => m.name === metricName);

      if (foundMetric) {
        // For each date range => push the corresponding period value
        // e.g. if foundMetric.periodValues = [ valForRange0, valForRange1, ...]
        // They should match up by index
        dateRanges.forEach((_, i) => {
          const val = foundMetric.periodValues?.[i];
          row.push(val ?? '');
        });
        // Then push the total
        row.push(foundMetric.total ?? '');
      } else {
        // No metric for this section => fill with empty
        for (let i = 0; i < dateRanges.length + 1; i++) {
          row.push('');
        }
      }
    });

    rows.push(row);
  });

  // Prepend an overall label to the header row
  const finalHeaderRow = ['Metric', ...headerRow];

  const csvContent = [finalHeaderRow, ...rows].map((cols) => cols.join(',')).join('\n');

  return csvContent;
}

export function useCsv() {
  return {
    convertPropertiesToCsv,
    convertBenchmarkReportToCsv,
    convertTrendingReportToCsv,
    downloadCsv,
  };
}
