<!-- Groups/TableLayout.vue -->
<script setup>
import { computed } from 'vue';
import { useTableSortingStore } from '@/stores/TableSortingStore';
import { useModalStore } from '@/stores/ModalStore';
import { useGroupsStore } from '@/stores/GroupsStore';
import SimpleBar from 'simplebar-vue';
import useTableStyles from '@/composables/useTableStyles';
import AppIconSortUp from '@/components/svg/AppIconSortUp.vue';
import AppIconSortDown from '@/components/svg/AppIconSortDown.vue';

const groupsStore = useGroupsStore();
const tableStore = useTableSortingStore();
const modalStore = useModalStore();

const tableId = 'groupsTable';

const handleSort = (dataKey) => tableStore.setSorting(tableId, dataKey);

const sortedData = computed(() => tableStore.getSortedData(tableId, groupsStore.filteredGroups));
const { cellStyle, getCellClass } = useTableStyles(groupsStore.groups, 8, {
  tableId,
  tableStore,
});

// Define the columns for the table
const columns = [
  { key: 'name', displayName: 'Group Name' },
  { key: 'tags', displayName: 'Group Type' },
  { key: 'property_names', displayName: 'Properties' },
  { key: 'last_updated', displayName: 'Last Updated' },
];

const getSortIcon = (dataKey) => {
  const direction = tableStore.arrowDirection(tableId, dataKey);
  if (direction === 'up') {
    return AppIconSortUp;
  } else if (direction === 'down') {
    return AppIconSortDown;
  }
  return null; // no icon by default
};

const handleManageGroupsModal = (row) => {
  modalStore.openModal('manageGroupsModal', {
    properties: row.property_names || [],
    groupName: row.name || '',
    groupType: row.tags || 'All',
  });
};
</script>

<template>
  <div class="wrapper">
    <SimpleBar>
      <!-- TABLE HEADER -->
      <div class="div-column-row">
        <div
          v-for="column in columns"
          :key="column.key"
          :style="cellStyle"
          :class="['header-cell sortable', getCellClass(column.key)]"
          @click="handleSort(column.key)"
        >
          <div class="header-cell-content">
            {{ column.displayName }}
            <component :is="getSortIcon(column.key)" v-if="getSortIcon(column.key)" class="sort-styles" />
          </div>
        </div>
      </div>

      <!-- TABLE BODY -->
      <div v-if="sortedData.length > 0">
        <div class="row-container" v-for="row in sortedData" :key="row.id">
          <div v-for="column in columns" :key="column.key" :style="cellStyle" :class="['cell', getCellClass(column.key)]">
            <div class="cell-content">
              <!-- Users Column -->
              <template v-if="column.key === 'users'">
                <template v-if="Array.isArray(row.users)">
                  <div class="user-row">
                    <div v-for="(user, index) in row.users" :key="user.id || index" class="users">
                      <img :src="user.avatar" :alt="user.name" class="user-content" />
                    </div>
                  </div>
                </template>
                <template v-else>
                  {{ row.users }}
                </template>
              </template>

              <!-- Properties Column -->
              <template v-else-if="column.key === 'property_names' && Array.isArray(row.property_names)">
                <span v-if="row.property_names.length > 1" class="multiple-properties" @click="handleManageGroupsModal(row)">
                  <span class="property-amount"> {{ row.property_names.length }} Properties </span>
                </span>
                <span v-else class="property-amount" @click="handleManageGroupsModal(row)"> {{ row.property_names.length }} Property </span>
              </template>

              <!-- Group Type Column -->
              <template v-else-if="column.key === 'tags' && row.tags">
                <span class="pill">{{ row.tags }}</span>
              </template>

              <!-- Last Updated Column -->
              <template v-else-if="column.key === 'last_updated' && row.last_updated">
                <span class="last-update">{{ row.last_updated }}</span>
              </template>

              <!-- Default Column (e.g., Group Name) -->
              <template v-else>
                {{ row[column.key] }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="cell no-results">No results</div>
    </SimpleBar>
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/settings/mixins';
@import 'simplebar-vue/dist/simplebar.min.css';

.wrapper {
  display: flex;
  flex-direction: column;
  border: var(--border-size) solid var(--secondary-color);
  border-radius: 18px;
  overflow-x: auto;
  background: var(--property-card-bg);
}

.user-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.users {
  position: relative;
  right: 15px;
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid var(--property-card-bg);
  overflow: hidden;

  &:first-child {
    right: 0;
    z-index: -1;
  }

  &:nth-child(3) {
    right: 25px;
    z-index: 0;
  }

  .user-content {
    position: absolute;
    z-index: -1;
  }
}

.last-update {
  font-weight: 300;
}

.multiple-properties {
  color: var(--primary-text-color);
  cursor: pointer;
}

.multiple-properties:hover {
  color: var(--primary-hover-color);
}

.property-amount {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color-opaque);
  border-radius: 50px;
  margin: 0;
  padding: 5px 15px;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}

:deep(.simplebar-scrollbar::before) {
  background-color: var(--secondary-color);
}

.div-column-row {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
}

.header-cell {
  display: flex;
  align-items: center;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-color);
  text-align: left;
  transition: color 0.3s ease;

  &.active-column {
    .header-cell-content {
      color: var(--primary-color);
    }
  }

  .header-cell-content {
    padding: 15px;
  }
}

.sort-styles {
  align-self: start;
  vertical-align: middle;
}

.sortable:hover {
  cursor: pointer;
}

.row-container {
  display: flex;
  min-width: max-content;
  border-bottom: var(--border-size) solid var(--secondary-color);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: hsla(216, 16%, 45%, 0.1);
  }

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  display: flex;
  align-items: center;
  flex: 1 0 var(--cell-base-size);
  min-width: var(--cell-base-size);
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  transition: background-color 0.3s ease;

  &:first-child {
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .cell-content {
    padding: 15px;
  }
}

.no-results {
  padding: 15px;
}

.pill {
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  margin: 0;
  padding: 5px 15px;
  display: inline-block;
  font-size: 12px;
  color: var(--primary-text-color);
}
</style>
